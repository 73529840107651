import React from 'react'
import { Button, Stack } from '@mui/material'
import Grid from '@mui/material/Grid2/Grid2'
import logomeka from '../../assets/logo.png'
import iconeevent from '../../assets/event.png'
import iconediscover from '../../assets/discover.png'
import iconemusic from '../../assets/music.png'
import iconestats from '../../assets/stats.png'
import { useNavigate } from 'react-router-dom'
import Player from '../player/Player'


export function SideMenu() {

    const navigate = useNavigate()

    const goToHome = async (e) => {
        navigate(`/`)
    }

    return <Grid size={{ lg:2, md: 2, xs: 0}} sx={{ height: '100%', display: { xs: 'none', md: 'flex', lg: 'flex'}}} justifyContent={'center'}>
        <Grid container sx={{ height: '100%'}}>
            <Grid size={12} sx={{ height: '83%'}}  >
                <Stack spacing={0} direction={'column'} sx={{ height: '100%'}}>
                    <Button sx={{ height: '20%'}} ><img alt="logo meka" height={'100%'} src={logomeka} onClick={goToHome} /></Button>
                    <Button sx={{marginTop: '4vh', height: '14%', padding: '8px'}}><img alt="logo event" height={'100%'} src={iconeevent} /></Button>
                    <Button sx={{marginTop: '4vh', height: '14%', padding: '8px'}}><img alt="logo music" height={'100%'} src={iconemusic} /></Button>
                    <Button sx={{marginTop: '4vh', height: '14%', padding: '8px'}}><img alt="logo discover" height={'100%'} src={iconediscover} onClick={goToHome} /></Button>
                    <Button sx={{marginTop: '4vh', height: '14%', padding: '8px'}}><img alt="logo stats" height={'100%'} src={iconestats} /></Button>
                </Stack>
            </Grid>
            <Grid size={12} sx={{ textAlign: 'center'}}>
                <Player />
            </Grid>
        </Grid> 
    </Grid>
}
