import { useState } from 'react'
import { Stack, Avatar, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2/Grid2'
import placeholder from '../../../assets/placeholder_artist.jpg'
import ArtistGraphMainCollabs from './ArtistGraphMainCollabs';
import useHorizontalScroll from '../../../utils/HorizontalScroll';


interface Collaboration {
    relations: [Relation],
    artist: Artist,
    occurs: number,
    roles: {}
}

interface Relation {
    occurs: number,
    artist: Artist,
    roles: {}
}
 
interface ArtistGraphSecondaryCollabsProps {
    selectedCollab: Collaboration
}

const ArtistGraphSecondaryCollabs = (props: ArtistGraphSecondaryCollabsProps) => {
    const [selectedRelation, setSelectedRelation] = useState<number>(0)
    const scrollRef = useHorizontalScroll()

    function sortOccurs(a: Relation, b: Relation) {
        return b.occurs - a.occurs
    }
    
    console.log(props.selectedCollab)
    console.log([...props.selectedCollab.relations].sort(sortOccurs))
    console.log(selectedRelation)
    return (
        <Grid size={12} >
            <Stack ref={scrollRef} direction={'row'} sx={{overflow: 'hidden', overflowX: 'scroll', mb: 1 }}>
            {
                [...props.selectedCollab.relations]
                .sort(sortOccurs)
                .map( (relation: Relation, i: number)  => (
                    <Stack 
                        key={i}
                        onClick={() => setSelectedRelation(i)}
                        sx={{ width: relation.artist._id === [...props.selectedCollab.relations].sort(sortOccurs)[selectedRelation].artist._id ? '6vw' : '3vw', p:2}} 
                        direction={'column'} 
                        alignSelf={'center'} 
                        alignContent={'center'} 
                        alignItems={'center'}
                        // ref={ref}
                    >
                        <Avatar 
                            sx={{ 
                                width: relation.artist._id === [...props.selectedCollab.relations].sort(sortOccurs)[selectedRelation].artist._id ? '3vw' : '2vw', 
                                height: relation.artist._id === [...props.selectedCollab.relations].sort(sortOccurs)[selectedRelation].artist._id ? '3vw' : '2vw',
                                '&:hover .artist_name': {
                                    display: 'block'
                                },
                                transition: 'all .2s ease-in-out',
                                transform: 'scale(1)',
                                '&:hover': {
                                    transform: relation.artist._id === [...props.selectedCollab.relations].sort(sortOccurs)[selectedRelation].artist._id ? 'scale(1)' : 'scale(1.5)'
                                }
                            }}
                            src={
                                relation.artist.avatar_url 
                                ? relation.artist.avatar_url.replace("cw.png","cc.png") 
                                : relation.artist.apple_avatar 
                                    ? relation.artist.apple_avatar.replace("cw.png","cc.png") 
                                    : placeholder
                            }
                        />
                        { 
                            relation.artist._id === [...props.selectedCollab.relations].sort(sortOccurs)[selectedRelation].artist._id 
                                ? <Typography sx={{whiteSpace: 'nowrap', mt: 1, fontWeight: 500, fontSize: '1vmin'}}>{relation.artist.artist_name}</Typography> 
                                : <Typography className='artist_name' sx={{whiteSpace: 'nowrap', mt: 1, fontWeight: 500, display: 'none'}}>{relation.artist.artist_name}</Typography> 
                        }
                    </Stack>
                ))
            }
            </Stack>
            {
                [...props.selectedCollab.relations].length > 0
                ? <ArtistGraphMainCollabs roles={[...props.selectedCollab.relations].sort(sortOccurs)[selectedRelation].roles} />
                : <ArtistGraphMainCollabs roles={[]} />
            }
        </Grid>     
    )    
    
                                
}

export default ArtistGraphSecondaryCollabs