import Chart from 'react-apexcharts';
import { Skeleton, Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useGetTrackStatsQuery } from '../../../api/trackApiSlice'
import { useGetAlbumTracksQuery } from '../../../api/albumApiSlice'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectCurrentStyle } from '../../../actions/discoverSlice';


const AlbumStats = () => {
    let { id } = useParams()
    const selectedStyle = useSelector(selectCurrentStyle)

    const {
        data: tracks
    } = useGetAlbumTracksQuery({id})

    const {
        data: trackStats,
    } = useGetTrackStatsQuery(tracks && tracks.length > 0 ? tracks.map( trackLiked => trackLiked.track._id) : null)

    
    return trackStats && trackStats.length > 0
    ?   <Box xs={12} sx={{overflow: 'hidden', height: '90vh', width: '100%'}}>
            <Chart options={{
                    grid: {
                        show: false
                    },
                    theme: {
                        mode: 'dark', 
                        monochrome: {
                            enabled: true,
                            color: '#08088A',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        }
                    },
                    chart: {
                        id: 'apexchart-example',
                        foreColor: '#FFFFFF',
                        background: '#ffffff00',
                        toolbar: {
                            show: false
                        },
                        animations: {
                            enabled: false,
                            easing: 'easeinout',
                            speed: 50,
                            animateGradually: {
                                enabled: true,
                                delay: 25
                            },
                            dynamicAnimation: {
                                enabled: true,
                                speed: 350
                            }
                        }
                    },
                    yaxis: {
                        min: 1,
                        max: Math.max(200, Math.max(...[...trackStats.map(trackStat => trackStat.stats.map( stat => stat.position ))].reduce((acc, val) => [...acc, ...val]))),
                        reversed: true,
                        position: 'right'
                    },
                    xaxis: {
                        position: 'top',
                        categories: trackStats[0].stats.map( stat => moment(stat.date).format('DD/MM') )
                    },
                    legend: {
                        show: true
                    },
                    
                    }} 
                    series={trackStats.map( trackStat => { 
                        return {
                            name: tracks.find( track => track.track._id === trackStat.track_id).track.apple_title,
                            data: trackStat.stats.filter(stat => stat.style+'' === selectedStyle+'').length > 0 
                                ? trackStat.stats.filter(stat => stat.style+'' === selectedStyle+'').map( stat => stat.position )
                                : trackStat.stats.filter(stat => stat.style+'' === trackStat.stats[0].style+'').map( stat => stat.position )
                        }
                    })}
                
                type="line" 
                width={'100%'} 
                height={'100%'} />       
            </Box>
    : 
        <Skeleton variant="rectangular" width={'50vw'} height={'70vh'} />
}

export default AlbumStats