import { useEffect, useState } from 'react'
import { Stack, Box, ButtonGroup, Button, Tooltip } from '@mui/material'
import ArtistGraphMainCollabsList from './ArtistGraphMainCollabsList'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import PianoIcon from '@mui/icons-material/Piano'
import GroupIcon from '@mui/icons-material/Group'

const chipEnableStyle = {
    color: '#17043d', 
    backgroundColor: '#ffffff',
    p:2,
    fontWeight: 700,
    fontSize: '1vmin',
    '&:hover': {
        backgroundColor: '#ffffff'
    }
}

const chipDisableStyle = {
    color: '#ffffff', 
    p: 2,
    fontSize: '1vmin'
}

const ArtistGraphMainCollabs = ({roles}) => {

    const [mode, setMode] = useState(null)

    useEffect(() => {
        if (roles.length > 0)
            setMode(roles[0].role) 
    }, [roles])
    

    return (
        <Stack direction={'row'} sx={{background: '#ffffff00', border: '0px solid white', borderRadius: '16px', p: 2}} alignItems={'center'}>
            <ButtonGroup orientation={'vertical'} sx={{height: '100%'}}>
                { 
                    roles.map(role => role.role).includes('interpretor') 
                    ?
                        mode === 'interpretor' 
                            ? <Tooltip title={'Interprètation' + (roles.find(role => role.role === mode) && roles.find(role => role.role === mode).tracks.length > 1 ? `s (${roles.find(role => role.role === mode).tracks.length})` : ' (1)')}>
                                <Button color='primary'sx={chipEnableStyle} >
                                    <RecordVoiceOverIcon sx={{color: '#17043d', fontSize: '1.8vmin'}} />
                                </Button>
                            </Tooltip>
                            : <Tooltip title={'Interprètation' + (roles.find(role => role.role === 'interpretor') && roles.find(role => role.role === 'interpretor').tracks.length > 1 ? `s (${roles.find(role => role.role === 'interpretor').tracks.length})` : ' (1)')}>
                                <Button color='white' onClick={() => setMode('interpretor')} sx={chipDisableStyle} variant="outlined">
                                    <RecordVoiceOverIcon sx={{color: '#ffffff', fontSize: '1.8vmin'}} />
                                </Button>
                            </Tooltip>
                    : ''
                }
                { 
                    roles.map(role => role.role).includes('producer') 
                    ?
                        mode === 'producer' 
                            ? <Tooltip title={'Production' + (roles.find(role => role.role === mode) && roles.find(role => role.role === mode).tracks.length > 1 ? `s (${roles.find(role => role.role === mode).tracks.length})` : ' (1)')}>
                                <Button color='primary' sx={chipEnableStyle} >
                                    <PianoIcon sx={{color: '#17043d', fontSize: '1.8vmin'}} />
                                </Button>
                            </Tooltip> 
                            : <Tooltip title={'Production' + (roles.find(role => role.role === 'producer') && roles.find(role => role.role === 'producer').tracks.length > 1 ? `s (${roles.find(role => role.role === 'producer').tracks.length})` : ' (1)')}>
                                <Button color='white' onClick={() => setMode('producer')} sx={{...chipDisableStyle, ...{ borderStyle: 'dashed'}}} variant="outlined">
                                    <PianoIcon sx={{color: '#ffffff', fontSize: '1.8vmin'}} />
                                </Button>
                            </Tooltip>
                    : ''
                }
                { 
                    roles.map(role => role.role).includes('featured') 
                    ?
                        mode === 'featured' 
                            ? <Tooltip title={'Collaboration' + (roles.find(role => role.role === mode) && roles.find(role => role.role === mode).tracks.length > 1 ? `s (${roles.find(role => role.role === 'featured').tracks.length})` : ' (1)')}>
                                <Button color='primary'sx={chipEnableStyle}>
                                    <GroupIcon sx={{color: '#17043d', fontSize: '1.8vmin'}} />
                                </Button>
                            </Tooltip> 
                            : <Tooltip title={'Collaboration' + (roles.find(role => role.role === 'featured') && roles.find(role => role.role === 'featured').tracks.length > 1 ? `s (${roles.find(role => role.role === 'featured').tracks.length})` : ' (1)')}>
                                <Button color='white'onClick={() => setMode('featured')} sx={{...chipDisableStyle, ...{ borderStyle: 'dotted'}}} variant="outlined">
                                    <GroupIcon sx={{color: '#ffffff', fontSize: '1.8vmin'}} />
                                </Button>
                            </Tooltip>
                    : <Box sx={{height: '2vh'}}></Box>
                }
                
            </ButtonGroup>  
            <ArtistGraphMainCollabsList role={roles.find(role => role.role === mode)} />
        </Stack>     
    )    
    
                                
}

export default ArtistGraphMainCollabs