import { Link, useParams } from 'react-router-dom'
import { Avatar, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2/Grid2'
import { song_title, artist_name, image_cover } from './Player.module.css';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import withMK from '../../hoc/withMK';
import { artworkForMediaItem } from '../../utils/Utils';
import { pause, play, skipToNext, skipToPrevious } from '../../services/MusicPlayerApi';
import { useGetTrackByAppleIdQuery } from '../../api/trackApiSlice';
import { withQueueModal, QueueModalProps } from '../../core/providers/QueueProvider';
import { ModalProviderValue, withModal } from '../../core/providers/ModalProvider';
import AppleMusicVolume from './components/appleMusicVolume';
import AppleMusicProgress from './components/appleMusicProgress';
import { useGetUserQuery } from '../../api/userApiSlice'

interface PlayerProps extends MKProps, QueueModalProps {
    authorized: boolean
    modal: ModalProviderValue
}

const Player = (props: PlayerProps) => {
    let { id } = useParams()
    
    const {
        data: user
    } = useGetUserQuery({})

    const { 
        data: track, 
        isLoading 
    } = useGetTrackByAppleIdQuery(props.mk.instance.queue.currentItem && props.mk.instance.queue.currentItem.id != null ? props.mk.instance.queue.currentItem.id : '1693872275')  
    
    const playing = props.mk.instance.isPlaying
    
    const nowPlayingItem = props.mk.instance.queue.currentItem
    
    const artworkURL = artworkForMediaItem(nowPlayingItem, 60)
    
    console.log(nowPlayingItem)

    return ( nowPlayingItem ?
            <Grid container spacing={2} alignItems={'center'} sx={{ backgroundColor: track && track.apple_artwork_color1 ? '#'+track.apple_artwork_color1 : '#5151a0', borderRadius: '16px', p: 1, height: '100%', overflow: 'hidden', position: 'relative'}}>
                
                <Button sx={{bottom: 0, left: 0, minWidth: '24px', width: '60px', height: '60px', position: 'absolute'}} >
                    <Link to={user ? '/profile' : '/login'}><Avatar alt="User avatar" src={user ? user.avatar_url : ''} sx={{margin: '0 auto', height: user ? '100%' : '50px', width: user ? '100%' : '50px'}}/></Link>
                </Button>
                    
                {/* <Grid size={12} sx={{paddingBottom: "16px", marginTop: "16px"}}>
                    <Stack direction={'row'} alignSelf={'center'} alignContent={'center'} alignItems={'center'}>
                        <Button sx={{width: '100%', '& svg': {fontSize: {xs: '4.5vmin', md: '1.5vmin', lg: '1.5vmin'}}}} onClick={props.queueModal.isOpen ? props.queueModal.close : props.queueModal.open}><AlbumIcon color='#fff' /></Button>
                        <Button sx={{width: '100%', '& svg': {fontSize: {xs: '4.5vmin', md: '1.5vmin', lg: '1.5vmin'}}}}><RandomIcon color='#fff' /></Button>
                        <Button sx={{width: '100%', '& svg': {fontSize: {xs: '4.5vmin', md: '1.5vmin', lg: '1.5vmin'}}}} onClick={() => props.mk.instance.authorize()} ><SpeakerIcon color='#fff' /></Button>
                    </Stack>
                </Grid> */}

                <Grid size={3} alignSelf={'start'} >
                    { artworkURL || track
                        ? <img src={artworkURL ? artworkURL : track.apple_artwork_url.replace('{w}', 300).replace('{h}', 300)} className='image-fit' alt="placeholder cover"/>
                        : <Avatar variant='square' sx={image_cover} alt="placeholder cover"><MusicNoteIcon/></Avatar>
                    }
                </Grid>

                <Grid size={9}>
                    <Grid container >
                        <Grid size={12}>
                            <Stack spacing={0} sx={{ mb: 0, width: '100%' }} alignItems="left">
                                <Typography sx={{...song_title, ...{textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}} >{
                                    track && track.apple_title
                                    ? <Link to={`/track/${track._id}`} style={{textDecoration: 'none', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}>{track.apple_title}</Link>
                                    : nowPlayingItem.attributes.name
                                }</Typography>
                                <Stack spacing={0} direction="row" sx={{whiteSpace: "nowrap", width: '90%', overflow: 'hidden', mb: 1, pb: 0}} alignItems="center">
                                    <Typography sx={{...artist_name, ...{ color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}} >
                                    {
                                        track && !isLoading
                                        ? 
                                        track.main_artists?.map( (artist: Artist, i: number) =>  {
                                            return track.main_artists && i === track.main_artists?.length - 1
                                            ? artist._id === id 
                                                ? artist.artist_name 
                                                : <Link key={i + artist._id} to={`/artist/${artist._id}`} style={{textUnderlineOffset: '3px', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}>
                                                    { artist.artist_name } 
                                                </Link>
                                            : artist._id === id 
                                                ? artist.artist_name + ', ' 
                                                : <span key={i + artist._id}>
                                                    <Link key={i + artist._id} to={`/artist/${artist._id}`} style={{textUnderlineOffset: '3px', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}>
                                                        { artist.artist_name } 
                                                    </Link>,
                                                </span>
                                        })
                                        
                                        : nowPlayingItem.attributes.artistName
                                    }
                                    </Typography>
                                    { track && track.featured_artists && track.featured_artists.length > 0
                                        ?
                                        <>
                                        <Typography sx={{...artist_name, ...{ color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}}>&</Typography>
                                        <Typography sx={{...artist_name, ...{ color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}}>
                                        {
                                            track && !isLoading
                                            ? 
                                            track.featured_artists?.map( (artist: Artist, i: number) =>  {
                                                return track.featured_artists && i === track.featured_artists?.length - 1
                                                ? artist._id === id 
                                                    ? artist.artist_name 
                                                    : <Link key={i + artist._id} to={`/artist/${artist._id}`} style={{textUnderlineOffset: '3px', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}> 
                                                        { artist.artist_name }
                                                    </Link>
                                                : artist._id === id 
                                                    ? artist.artist_name + ', ' 
                                                    : <span key={i + artist._id}>
                                                        <Link key={i + artist._id} to={`/artist/${artist._id}`} style={{textUnderlineOffset: '3px', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}> 
                                                            { artist.artist_name } 
                                                        </Link>, 
                                                    </span>
                                            })
                                            
                                            : '--'
                                        }
                                        </Typography>
                                        </>
                                        : ''
                                    }
                                </Stack>

                                <Stack spacing={0} direction="row" sx={{ height: '1vh', pl:1.2, pr:1.2, pt: 2.3, pb: 1, mb: 1, '& apple-music-progress' :{ width: '100%', }, borderRadius: '24px', backgroundColor: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff' }} alignItems="center" >
                                    <AppleMusicProgress theme={track && track.apple_artwork_bg && isLight(track.apple_artwork_bg) ? 'dark' : 'light'} />
                                </Stack>

                                <Stack spacing={0} direction="row" sx={{ m: 0, whiteSpace: "nowrap", maxWidth: '90%'}} alignSelf={'flex-end'}>
                                    <Typography sx={{...artist_name, ...{ color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff', fontSize: '0.8em'}}} >{
                                        track && track.producers && track.producers.length > 0 && !isLoading
                                        ? track.producers.map( (artist: Artist, i: number) =>  {
                                            return track.producers && i === track.producers?.length - 1
                                            ?  <Link 
                                                    key={i + artist._id} 
                                                    to={`/artist/${artist._id}`} 
                                                    style={{textDecoration: artist._id === id ? 'none' : 'underline', textUnderlineOffset: '3px', textOverflow: 'ellipsis', maxWidth: (9 / track.producers.length)+'vw', overflow: 'hidden', display: 'inline-block', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}>
                                                        {artist.artist_name }
                                                </Link>
                                            :  <span key={i + artist._id}>
                                                    <Link 
                                                        to={`/artist/${artist._id}`} 
                                                        style={{textDecoration: artist._id === id ? 'none' : 'underline', textUnderlineOffset: '3px', textOverflow: 'ellipsis', maxWidth: (9 / track.producers.length)+'vw', overflow: 'hidden', display: 'inline-block', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}>
                                                            { artist.artist_name }
                                                    </Link>{', '} 
                                                </span>
                                        })
                                        : <Link 
                                        to={"/#"}
                                        style={{textDecoration: 'none', textUnderlineOffset: '3px', textOverflow: 'ellipsis', maxWidth: (9 / (track && track.producers && track.producers.length > 0 && !isLoading ? track.producers.length : 1))+'vw', overflow: 'hidden', display: 'inline-block', color: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}
                                        >{nowPlayingItem.attributes.composerName}</Link>
                                    }</Typography>
                                </Stack>
                            </Stack>
                        </Grid>

                        <Grid size={6} alignContent={'center'}>
                            <Stack spacing={2} direction="row" alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{'& apple-music-volume' :{ width: '100%', borderRadius: '16px', p: 0.5, backgroundColor: track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'}}}>
                                <AppleMusicVolume theme={track && track.apple_artwork_bg && isLight(track.apple_artwork_bg) ? 'dark' : 'light'} />
                            </Stack>
                        </Grid>   

                        <Grid size={6}>
                            <Stack direction={'row'}>
                                <Button sx={{minWidth: '1px', width: '100%'}} onClick={() => skipToPrevious()}><SkipPreviousIcon htmlColor={ track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'} sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/></Button>
                                <Button sx={{minWidth: '1px', width: '100%'}} onClick={playing ? () => pause() : () => play()}>
                                {
                                    playing
                                    ? <PauseIcon htmlColor={ track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'} sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/>
                                    : <PlayArrowIcon htmlColor={ track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'} sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/>
                                }
                                </Button>
                                <Button sx={{minWidth: '1px', width: '100%'}} onClick={() => skipToNext()}><SkipNextIcon htmlColor={ track && track.apple_artwork_bg ? '#'+track.apple_artwork_bg : '#ffffff'} sx={{fontSize: {xs: '4vmin', md: '2vmin', lg: '2vmin'}, p:0 }}/></Button>
                            </Stack>
                        </Grid>

                    </Grid>           
                </Grid>           

            </Grid>
     : user 
        ? <Button sx={{marginTop: '4vh', height: '10%', }} ><Link to={'/profile/' + user._id}><Avatar alt="User avatar" src={user.avatar_url} sx={{margin: '0 auto', height: '8vh', width: '8vh'}}/></Link></Button>
        : <Button sx={{marginTop: '4vh', height: '10%', }} ><Link to='/login'><Avatar alt="User avatar" sx={{margin: '0 auto', height: '8vh', width: '8vh'}}/></Link></Button>
    )
}

const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}

export default withMK(
    withQueueModal(withModal( Player )), 
    bindings
)

const isLight = (colorHex: string) => {
    var c: any = colorHex.substring(1);      // strip #
    var rgb = parseInt(c, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue
    
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    
    if (luma < 40) {
       return true
    }
}