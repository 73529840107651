import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../actions/authSlice'
import { useRefreshMutation } from '../../api/authApiSlice'
import { Skeleton } from '@mui/material'
import { Outlet } from 'react-router-dom'
import logomeka from '../../assets/logo.png'
 
 const PersistLogin = () => {

    const persist = true
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)
    const [trueSuccess, setTrueSuccess] = useState(false)

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
    }] = useRefreshMutation()

    useEffect(() => {

        if (effectRan.current === true) return
        effectRan.current = true

        const verifyRefreshToken = async () => {
            try {
                await refresh()
                setTrueSuccess(true)
            }
            catch (err) {
                console.log(err)
            }
        }

        if (!token && persist) verifyRefreshToken()
      
        // eslint-disable-next-line
    }, [])
    
    let content = <Outlet />
    if (!persist) { // persist: no
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        content = <Skeleton sx={{height: '100vh', width: '50vw', borderRadius: '32px', backgroundColor: '#08084f', backgroundImage: 'url('+logomeka+')', margin: '0 auto', backgroundRepeat: 'no-repeat', backgroundSize: '28% 55%', backgroundPosition: 'center'}}/>
    } else if (isError) { //persist: yes, token: no
        content = <Outlet />
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        content = <Outlet />
    }

    return content
 }
 
 export default PersistLogin