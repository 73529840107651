import Chart from 'react-apexcharts';
import { Skeleton,Box } from '@mui/material'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetAlbumTracksQuery } from '../../../api/albumApiSlice'
import placeholder from '../../../assets/placeholder_artist.jpg';


const AlbumArtists = () => {
    let { id } = useParams()

    const {
        data: tracks,
        isFetching
    } = useGetAlbumTracksQuery({id})


    const allSingleArtists = useMemo(() => {
        if(!tracks || tracks.length === 0)
            return []
        var mainArtists = []
        tracks.map( track => track.track.main_artists.forEach( artist => { if (!mainArtists.some(art => art._id === artist._id )) { mainArtists.push(artist) } })) 
        tracks.map( track => track.track.featured_artists.forEach( artist => { if (!mainArtists.some(art => art._id === artist._id )) { mainArtists.push(artist) } })) 
        tracks.map( track => track.track.producers.forEach( artist => { if (!mainArtists.some(art => art._id === artist._id )) { mainArtists.push(artist) } }))
        return mainArtists
    }, [tracks])

    const allArtists = useMemo(() => {
        if(!tracks || tracks.length === 0)
            return []
        var mainArtists = []
        tracks.map( track => track.track.main_artists.forEach( artist => { mainArtists.push(artist) } ) )
        tracks.map( track => track.track.featured_artists.forEach( artist => { mainArtists.push(artist) }) )
        tracks.map( track => track.track.producers.forEach( artist => { mainArtists.push(artist) }) )
        return mainArtists
    }, [tracks])


    return allArtists && allArtists !== null && !isFetching
    ?   
        <Box sx={{overflow: 'hidden', height: '90vh', width: '100%'}} alignSelf={'center'}>
            <Chart 
            options={{
                plotOptions: {
                    treemap: {
                        useFillColorAsStroke: true,
                    }
                },
                grid: {
                    show: false
                },
                theme: {
                    mode: 'dark', 
                },
                yaxis: {
                    show: false,
                },
                xaxis: {
                    show: false,
                },
                chart: {
                    type: "treemap",
                    foreColor: '#FFFFFF',
                    background: '#ffffff00',
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                    },
                    animations: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: false,
                    }
                },
                fill: {
                    type: 'image',
                    image: {
                        src: allSingleArtists.map( artist => artist.avatar_url 
                            ? artist.avatar_url.replace("{w}","1800").replace("{h}","1200").replace("cw.png","cc.png") 
                            : (artist.apple_avatar ? artist.apple_avatar.replace("cw.png","cc.png").replace("{w}","1800").replace("{h}","1200") : placeholder))
                    },
                    
                }
                }}
                series={
                    allSingleArtists.map( artist =>  [{
                        name: artist.artist_name,
                        data: [{
                            x: artist.artist_name,
                            y: allArtists.filter( gArtist => gArtist._id === artist._id).length
                        }]
                    }].reduce((acc, val) => [...acc, ...val]))
                }         
                type="treemap" 
                width={'103%'} 
                height={'100%'}
            />
        </Box>    
    : 
        <Skeleton variant="rectangular" width={'90vw'} height={'90vh'} />
}

export default AlbumArtists