import { Button, TextField } from '@mui/material'
import { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLoginMutation } from '../../api/authApiSlice'
import { setCredentials, setUser } from '../../actions/authSlice'
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid2/Grid2'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const Login = () => {
    const emlRef = useRef()
    const errRef = useRef()
    const [eml, setEml] = useState('')
    const [pwd, setPwd] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate()

    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        emlRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('')
    }, [eml, pwd])


    const handleSignUp = async (e) => {
        navigate('/register')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const userData = await login({email: eml, password: pwd}).unwrap()
            dispatch(setCredentials({accessToken: userData.accessToken}))
            dispatch(setUser({user: userData.user}))
            setEml('')
            setPwd('')
            navigate('/profile')
        } catch (err) {
            if (!err?.data) {
                setErrMsg('Pas de réponse du serveur')
            } else if (err.response?.status === 400) {
                setErrMsg('Il manque le mot de passe ou le mail')
            } else if (err.response?.status === 401) {
                setErrMsg('Vous n\'êtes pas autorisé')
            } else {
                setErrMsg('Connexion échouée')
            }
        }
    }

    const handleEmlInput = (e) => setEml(e.target.value)

    const handlePwdInput = (e) => setPwd(e.target.value)

    const content = (
            <Grid size={2} offset={2}
                component="form"
                sx={{
                '& > :not(style)': { m: 1 },
                '& .MuiTextField-root': { m: 1, width: '80%' },
                '& .MuiInputBase-input': { color: 'white' },
                position: "absolute",
                top: "50%",
                transform: "translate(50%, -50%)",
                textAlign: "center"
                }}
                noValidate
                autoComplete="off" >
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>
                    <h2>Connexion</h2>
                    <hr/>
                    <TextField
                        required
                        focused
                        display="block"
                        label="E-mail"
                        color="white"
                        ref={emlRef}
                        value={eml}
                        onChange={handleEmlInput}
                        sx={{
                            '& .MuiInputBase-input': { color: 'white!important' },
                        }}
                    />
                    <TextField
                        required
                        focused
                        color="white"
                        display="block"
                        label="Password"
                        type="password"
                        value={pwd}
                        onChange={handlePwdInput}
                        sx={{
                            '& .MuiInputBase-input': { color: 'white!important' },
                        }}
                    />
                    <hr/>
                    <Button onClick={handleSignUp} variant="text" endIcon={<PersonAddAlt1Icon />} color="white">
                        Je n'ai pas de compte
                    </Button>
                    <Button disabled={isLoading} onClick={handleSubmit} variant="outlined" endIcon={<SendIcon />} color="white">
                        Envoyer
                    </Button>
            </Grid>  
        )

    return content
}

export default Login