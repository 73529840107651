
import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const RequireAuth = () => {
const location = useLocation() 
const { id } = useAuth()

  return (
    id 
    ? <Outlet /> 
    : <Navigate to='/login' state={{ from: location }} replace />
  )
}

export default RequireAuth