import React, { useEffect, useMemo, useState } from 'react'
import { Skeleton } from '@mui/material'
import Grid from '@mui/material/Grid2/Grid2'
import { useParams  } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { saveRecentArtist } from '../../actions/mainSlice'
import ArtistSideMenu from './components/board/ArtistSideMenu'
import ArtistGraph from './components/ArtistGraph'
import { useGetArtistCollabsQuery } from '../../api/artistApiSlice'
import placeholder from '../../assets/placeholder_artist.jpg'
import { v4 as uuidv4 } from 'uuid'

const ArtistBoard = () => {
    let { id } = useParams()
    const dispatch = useDispatch()
    let [network, setNetwork] = useState(null)
    let [focusedPosition, setFocusedPosition] = useState({x:0, y:0})
    let [stayFocus, setStayFocus] = useState(false)
    let [selectedCollab, setSelectedCollab] = useState(null)
    let [graphKey, setGraphKey] = useState(uuidv4)


    const {
        data: collabs
    } = useGetArtistCollabsQuery({id})

    useEffect(() => {
        dispatch(saveRecentArtist(id))
        setGraphKey(uuidv4)
    }, [dispatch, id])

    const handleCloseCollab = () => {
        setSelectedCollab(null)
        setStayFocus(false)
        network.moveTo({
            position: focusedPosition,
            scale: 1,
            animation: { 
                duration: 500,
                easingFunction: 'easeInOutCubic'
            }
        })
        network.unselectAll()
        network.releaseNode()
    }

    let graphDatas = useMemo(() => {
        if (collabs && collabs.length > 0) {
            if (stayFocus)
                setSelectedCollab(collabs.find(collab => collab.artist._id === selectedCollab.artist._id))

            var filterDouble = []
            collabs.map( (stat) => {
                return [{
                        from: stat.artist._id,
                        to: id,
                        color: stat.roles.includes('producer') ? "#56bfc5" : stat.roles.includes('featured') ? '#2cafb7' : '#1e7a80',
                        value: stat.occurs,
                        dashes: stat.roles.join() === 'featured',
                        arrows: {
                            to: {
                              enabled: false,
                            },
                            middle: {
                              enabled: false,
                            },
                            from: {
                              enabled: false,
                            }
                        } 
                    },
                    ...stat.relations.map( rArtist => { return {
                        from: stat.artist._id,
                        to: rArtist.artist._id,
                        color: rArtist.roles.map( role => role.role).includes('producer') ? "#56bfc5" : rArtist.roles.map( role => role.role).includes('featured') ? '#2cafb7' : '#1e7a80',
                        value: rArtist.occurs,
                        dashes: rArtist.roles.map( role => role.role).includes('producer') ? [8,24] : rArtist.roles.map( role => role.role).includes('featured') ? [0,8] : false,
                        arrows: {
                            to: {
                              enabled: false,
                            },
                            middle: {
                              enabled: false,
                            },
                            from: {
                              enabled: false,
                            }
                        }
                    }})]
            })
            .reduce((acc, val) => [...acc, ...val])
            .map(edge => {
                if (filterDouble.filter(dEdge => dEdge.to === edge.from && dEdge.from === edge.to).length === 0)
                    filterDouble.push(edge)
                return edge
            })
            return {
                nodes: collabs.map( (stat) =>  { 
                 return {
                    id: stat.artist._id,
                    label: stat.artist.artist_name,
                    group: stat.roles.map(role => role.role ).sort().join('_'),
                    shape: 'circularImage',
                    margin: 10,
                    scaling: {
                        min: 10,
                        max: 80
                    },
                    image: stat.artist.avatar_url ? stat.artist.avatar_url.replace("cw.png","cc.png").replace("{w}","800").replace("{h}","600") : stat.artist.apple_avatar ? stat.artist.apple_avatar.replace("cw.png","cc.png").replace("{w}","800").replace("{h}","600") : placeholder,
                    title: stat.artist.artist_name,
                    widthConstraint: false,
                    color: {
                        border: '#000000',
                        background: '#000000',
                        highlight: {
                            border: '#ffffff',
                            background: '#ff00ff'
                        },
                        hover: {
                            border: '#2D2D2D',
                            background: '#2D2D2D'
                        }
                    },
                    chosen: {
                        node: function(values, id, selected, hovering) {
                            if (selected === true) {
                                // console.log(values)
                                values.size = 50
                                
                                // values.value = 100
                                values.color = '#d2d2d2'
                                values.borderColor = '#ffffff'
                                values.borderWidth = 5
                                values.shadowSize = 0
                            }
                        },
                        label: function(values, id, selected, hovering) {
                            console.log(values)
                            if (selected === true)
                                values.color = '#00000000'
                        },
                    },
                    value: stat.occurs,
                    size: stat.occurs,
                    mass: stat.occurs,
                }}),
                edges: filterDouble
            }
        } else return []
       
        }, [id, collabs, stayFocus, selectedCollab])

        const events =  useMemo(() => {return {
            click: function(event) {
                var { nodes } = event
    
                // let edgeData = graphDatas.edges.find( edge => edge.id === edges[0])
                // if (edgeData) {
                //     console.log(collabs.find( collab => collab.artist._id === edgeData.from).relations.find( relation => relation.artist._id === edgeData.to) )
                //     let collab = collabs.find( collab => collab.artist._id === edgeData.from).relations.find( relation => relation.artist._id === edgeData.to)
                //     if ( collab && collab.artist ) {
                //         console.log(`${collab.artist.artist_name}, ${collab.roles[0].role} de ${collab.roles[0].tracks.map( track => track.apple_title).join(',')}` )
                //     }
                // }

                if (nodes[0] && selectedCollab && nodes[0]+'' === selectedCollab.artist._id+'') {
                    // if (nodes[0]+'' === id) {
                        handleCloseCollab()
                    // } else {
                    //     setStayFocus(true)
                    //     navigate(`/artist/${nodes[0]}`)
                    //     refetch()
                    // }
                    // handleCloseCollab()
                } else {
                    if (nodes[0]) { 
                        network.focus(nodes[0], {
                            locked: true,
                            scale: 4,
                            position: focusedPosition,
                            offset: {x: -(document.getElementById(`${id}`).offsetWidth/2) + 140, y: -(document.getElementById(`${id}`).offsetHeight/2) + 140},
                            animation: { // -------------------> can be a boolean too!
                                duration: 300,
                                easingFunction: 'easeInOutCubic'
                            }
                        })
                        setFocusedPosition(network.getPosition(nodes[0]))
                        setSelectedCollab(collabs.find(collab => collab.artist._id+'' === nodes[0]+''))
                        setStayFocus(true)
                    } else {
                        handleCloseCollab()
                    }
                }
            },
            startStabilizing: function(event) {
                console.log('starting')
                // console.log(graphDatas)
            },
            stabilized: function(event) {
                var { iterations } = event
                console.log('ended : '+ iterations)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            }}, [id, collabs, graphDatas, network, focusedPosition, selectedCollab])

    return (
        <Grid container spacing={2} sx={{height: '100%'}} >
            <ArtistSideMenu />
            <Grid size={{ lg: 8, xs: 12}} sx={{height: '100%'}}>
                {
                    collabs && collabs.length > 0
                    ? <ArtistGraph id={id} graphKey={graphKey} collabs={collabs} graphDatas={graphDatas} events={events} setNetwork={setNetwork} selectedCollab={selectedCollab} handleCloseCollab={handleCloseCollab}/>
                    : <Skeleton /> 
                }
            </Grid>
        </Grid>
    )
}

export default ArtistBoard