// import { Theme } from "@emotion/react"
import { Button } from "@mui/material"
import { pause, play, playTrack } from "../../services/MusicPlayerApi"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import withMK from "../../hoc/withMK";

interface PlayPauseTrackButtonProps extends MKProps {
    apple_id: string
    // style: SxProps<Theme>
}

const PlayPauseTrackButton = (props: PlayPauseTrackButtonProps) => {
    const currentTrackId = props.mk && props.mk.instance.queue.currentItem ? props.mk.instance.queue.currentItem.id : null
    const playing = props.mk?.instance?.isPlaying

    return playing && currentTrackId+'' === props.apple_id+''
    ? <Button onClick={() => pause()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><PauseIcon htmlColor='white' /></Button>
    : currentTrackId+'' === props.apple_id+''
        ? <Button onClick={() => play()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><PlayArrowIcon htmlColor='white' /></Button>
        : <Button onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: props.apple_id, attributes: {}})], 0)} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><PlayArrowIcon htmlColor='white' /></Button>
}


const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}
  

export default withMK( PlayPauseTrackButton, bindings )