import { pause, playTrack } from '../../../../services/MusicPlayerApi'
import { Button, Skeleton, Stack, Typography, Avatar, Box, Menu, Breadcrumbs, Modal } from '@mui/material'
import Grid from '@mui/material/Grid2/Grid2'
import { Link, useParams } from 'react-router-dom'
import { useGetUserQuery, useLikeByTypeMutation } from '../../../../api/userApiSlice'
import { useGetTrackQuery } from '../../../../api/trackApiSlice'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import AMBadgeListenIn from '../../../../assets/AMBadgeListenIn.svg'
import FavoriteIcon from '@mui/icons-material/Favorite'
import placeholder from '../../../../assets/placeholder_artist.jpg'
import ShareIcon from '@mui/icons-material/Share'
import LibraryMusicIcon from '@mui/icons-material/PlaylistAdd'
import React from 'react'
// import styles from '../../Artist.module.css'
import withMK from '../../../../hoc/withMK'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';
import AlbumIcon from '@mui/icons-material/Album';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { useDispatch } from 'react-redux'
import { setShowSubscribe } from '../../../../actions/mainSlice'
import { setAddPlaylistTrack } from '../../../../actions/artistSlice'
const parse = require('html-react-parser')


const style = {
    position: 'absolute',
    top: '40%',
    left: '31%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'primary.main',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: '22vw',
    color: '#fff'
};

interface TrackSideMenuProps extends MKProps {
}

const TrackSideMenu = ({mk}: TrackSideMenuProps) => {
    let { id } = useParams()
    let dispatch = useDispatch()
    const currentTrackId = mk && mk.instance.queue.currentItem ? mk.instance.queue.currentItem.id : null
    const [likeTrack] = useLikeByTypeMutation()
    const playing = mk?.instance?.isPlaying
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const openMenu = Boolean(anchorEl)
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
      setOpen(true)
    }

    const handleClose = () => {
      setOpen(false)
    }

    const handleClickOpen = () => {
        dispatch(setShowSubscribe({showSubscribe: true}))
    }
  
    const {
        data: user,
        refetch
    } = useGetUserQuery({})

    const {
        data: track,
    } = useGetTrackQuery({id})

    const handleClickShare = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const likeTrackById = async (e: any, ID: string) => {
        e.preventDefault()
        try {
            const like = await likeTrack({target_id: ID, type: 'track'}).unwrap()
            refetch()
            if(like) {
                console.log('TODO: Use like')
            }
        } catch (error) {
            handleClickOpen()
        }
    }

    const addTrackToPlaylistById = async (track: Track) => {
        if (user)
            dispatch(setAddPlaylistTrack({track: track}))
        else
            dispatch(setShowSubscribe({showSubscribe: true}))
    }

    return track ? (
        <Grid size={{ lg: 4, xs: 12}} sx={{height: '100%',}}>
            <Stack sx={{
                height: '100%', 
                borderRadius: '16px',
                overflow: 'hidden', 
                backgroundImage: `url(${track && track.track.album ? track.track.apple_artwork_url.replace("cw.png","cc.png").replace('{h}', track.track.apple_artwork_width).replace('{w}', track.track.apple_artwork_width) : ''})`, 
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center'}}
            >
                <Stack sx={{backdropFilter: 'blur(20px)  brightness(70%)', width: '100%', height: '100%', position: 'relative', borderRadius: '16px'}}> 

                    <Breadcrumbs aria-label="breadcrumb" sx={{ p: 2}}>
                        <Link
                            style={{ display: 'flex', alignItems: 'center' }}
                            color="white"
                            to={"/artist/"+track.track.main_artists[0]._id}
                        >
                            <SettingsAccessibilityIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {track.track.main_artists[0].artist_name.length > 20 ? `${track.track.main_artists[0].artist_name.slice(0, 19)}...` : track.track.main_artists[0].artist_name}
                        </Link>
                        <Link
                            style={{ display: 'flex', alignItems: 'center' }}
                            color="white"
                            to={"/album/"+track.track.album._id}
                        >
                            <AlbumIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {track.track.album.apple_name.length > 20 ? `${track.track.album.apple_name.slice(0, 19)}...` : track.track.album.apple_name}
                        </Link>
                        <Link
                            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                            to={"/track/"+track.track._id}
                        >
                            <MusicNoteIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            {track.track.apple_title.length > 20 ? `${track.track.apple_title.slice(0, 19)}...` : track.track.apple_title}
                        </Link>
                    </Breadcrumbs>

                    <Stack direction="row" alignItems="center" sx={{ pl: 8, pr: 8 }}>
                        <Avatar 
                            variant='square'
                            alt={track.track.apple_title} 
                            src={track.track.apple_artwork_url ? track.track.apple_artwork_url.replace("cw.png","cc.png").replace('{h}', track.track.apple_artwork_width).replace('{w}', track.track.apple_artwork_width) : placeholder}
                            sx={{width: '100%', height: '100%', borderRadius: '16px'}}
                                />
                    </Stack>

                    <Stack direction={'column'} alignItems={'center'}>
                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{pb: 1, pt: 1}}>
                            {
                                playing && track && track.track && track.track.apple_id+'' === currentTrackId+''
                                    ? <Button onClick={() => pause()} sx={{width: '6vh',p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <PauseIcon htmlColor={'white'} fontSize='large' /> </Button>
                                    : <Button onClick={() => playTrack([new MusicKit.MediaItem({type: 'song', id: track.track.apple_id+'', attributes: {}})], 0)} sx={{width: '6vh',p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <PlayArrowIcon htmlColor={'white'} fontSize='large' /> </Button>
                            }
                            <Button onClick={(e) => likeTrackById(e, track.track._id)} sx={{p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <FavoriteIcon htmlColor={track && user && user.likes && user.likes.filter((like: any) => like.target_id === track.track._id).length > 0 ? '#FF5964' : 'white'} fontSize='large' /> </Button>
                            <Button onClick={() => addTrackToPlaylistById(track)} sx={{width: '6vh',p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <LibraryMusicIcon htmlColor={'white'} fontSize='large' /> </Button>
                            <Button onClick={handleClickShare} sx={{width: '6vh', p: 2, m: 2, ml: 0, borderRadius: '16px', backgroundColor: '#00000080'}}> <ShareIcon htmlColor={'white'} fontSize='large' /> </Button>
                            <Menu
                                id="share-menu"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                MenuListProps={{
                                'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleCloseMenu}
                                sx={{p: 0}}
                            >
                                <Button><FacebookIcon sx={{p: 2}}/> </Button>
                                <Button><TwitterIcon sx={{p: 2}}/> </Button>
                                <Button><InstagramIcon sx={{p: 2}}/> </Button>
                                <Button><WhatsAppIcon sx={{p: 2}}/> </Button>
                            </Menu>
                        </Stack>
                    </Stack>

                    <Typography variant='h3' sx={{fontWeight: 800, width: '100%', fontSize: '3vmin', pl: 2 }}>
                        {track.track.album.apple_artist_name}
                    </Typography>

                    <Box className={'content'} sx={{pl: 2}}>
                    {
                        track.track.album.apple_notes && track.track.album.apple_notes.length > 0 
                            ? 
                                <Box sx={{ p: 0, m: 0}}>
                                    <Grid container>
                                        <Grid size={8}>
                                            <Button onClick={handleOpen} sx={{color: 'white!important', textDecoration: 'underline', textTransform: 'initial', p: 0 }} >Notes sur l'album</Button>
                                        </Grid>
                                        <Grid size={4}>
                                            <Typography sx={{pr: 2, textAlign: 'right'}}><i>{'Sorti le ' + (new Date(track.track.apple_release_date)).toLocaleDateString()}</i></Typography>
                                        </Grid>
                                    </Grid>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                    <Box sx={style}>
                                    {
                                        parse(track.track.album.apple_notes)
                                    }
                                    </Box>
                                    </Modal>
                                </Box>
                            : 
                                <Typography sx={{pr: 2, textAlign: 'right'}}><i>{'Sorti le ' + (new Date(track.track.apple_release_date)).toLocaleDateString()}</i></Typography>
                    }
                    </Box>

                    <Typography variant='h4' sx={{fontWeight: 200, width: '100%', pl: 2, fontSize: '4vmin'}}>
                        {track.track.apple_title}
                    </Typography>

                    <Grid container alignItems={'flex-end'} sx={{height: '100%', p: 2 }}>
                        <Grid size={9}>
                            <Typography className={'credits'}>{track.track.album.record_label ? track.track.album.record_label.name.length > 120 ? track.track.album.record_label.name.slice(0, 119)+'...' : track.track.album.record_label.name : '--'}</Typography>                
                            <Typography className={'credits'}>{track.track.album.apple_copyright && track.track.album.apple_copyright.length > 120 ? track.track.album.apple_copyright.slice(0,119)+'...' : track.track.album.apple_copyright }</Typography> 
                        </Grid>
                        <Grid size={3} sx={{textAlign: 'right'}}>
                            <a href={track.track.album.apple_url} target='blank'><img alt='apple music link' src={AMBadgeListenIn} /></a>                
                        </Grid>      
                    </Grid>

                </Stack>
            </Stack>
        </Grid>
    )
  : <Skeleton />
}

const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}
  
export default withMK(TrackSideMenu, bindings)