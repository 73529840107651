import React, { useState } from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { useGetPlaylistsQuery } from '../../../api/discoverApiSlice'
import { Avatar, Button, Container, Skeleton, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Grid from '@mui/material/Grid2/Grid2'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { useSelector } from 'react-redux'
import { selectCurrentStyle } from '../../../actions/discoverSlice'
import { playTrack } from '../../../services/MusicPlayerApi'


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
    padding: '16px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    position: 'relative',
    width: '50%'
}))

export const PlaylistsFocus = () => {
    var [currentIndex, setCurrentIndex] = useState(0) 
    const selectedStyle = useSelector(selectCurrentStyle)

    const {
        data: playlists,
        isFetching
    } = useGetPlaylistsQuery(selectedStyle)

    const showNextArtist = () => {
        if (currentIndex + 1 === playlists.length || currentIndex > playlists.length) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const showPreviousArtist = () => {
        if (currentIndex - 1 === -1 || currentIndex < 0) {
            setCurrentIndex(playlists.length - 1)
        } else {
            setCurrentIndex(currentIndex - 1)
        }
    }

    return playlists && playlists.length > 0 && !isFetching
    ?
        <Item sx={{}}>
            <Stack direction={'row'}>
                <Button onClick={() => playTrack(playlists[currentIndex].tracks.map( (track: any) => new MusicKit.MediaItem({type: 'song', id: track.apple_id+''})), 0)} key={8} color="secondary" sx={{height: '6vh', width: '3.5vh', minWidth: '3.5vh', borderRadius: '3vh', border: '1px solid black', mr:1}} ><PlayArrowIcon sx={{p:0}} htmlColor='black' /></Button>
                <Avatar src={playlists[currentIndex].user.avatar_url} alt='user avatar' sx={{height: '6vh', width: '3.5vh', borderRadius: '3vh'}} />
            </Stack>
            <Stack sx={{height: '60%', overflow: 'hidden', ml: 4, mt: 2}}>
            {
                playlists[currentIndex].tracks.map((track: Track, i: number) => {
                    return (
                        <Stack key={i} direction="row" sx={{p:1}} alignItems={'center'}>
                            <Avatar variant='square' src={track.album.apple_artwork_url.replace('{w}', '300').replace('{h}', '300')} sx={{borderRadius: '8px'}} />
                            <Stack justifyContent="center" sx={{ marginLeft: '16px', width: '80%', color: 'primary' }}>
                                <Link to={`/track/${track._id}`}><Typography variant='h6' color='primary' sx={{textAlign: 'left', fontSize: '1em'}} textAlign={'left'}>{track.apple_title}</Typography></Link>
                                <Stack direction="row" sx={{overflow: 'hidden'}}>
                                    {
                                        track.main_artists.map((artist: Artist, i: number) => {
                                            return i === track.main_artists.length - 1
                                                ? <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} color='primary' > <Typography variant='caption' color={'primary'} sx={{ marginRight: '4px' }}>{artist.artist_name}</Typography> </Link>
                                                : <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > <Typography color={'primary'} variant='caption' sx={{ marginRight: '4px' }}>{artist.artist_name}</Typography> </Link> 
                                        })
                                    }
                                    {
                                        track.featured_artists && track.featured_artists.length > 0
                                            ?
                                                <> 
                                                    ft. {track.featured_artists.map((artist: Artist, i: number) => {
                                                        return track.featured_artists && i === track.featured_artists.length - 1
                                                            ? <Link key={Date.now() + i + artist._id} to={`/artist/${artist._id}`} > <Typography variant='caption' color={'primary'} sx={{ marginLeft: '4px' }}>{artist.artist_name}</Typography> </Link>
                                                            : <span key={Date.now() + i + artist._id}><Link to={`/artist/${artist._id}`} > <Typography variant='caption' color={'primary'} sx={{ marginLeft: '4px' }}>{artist.artist_name}</Typography> </Link>, </span>
                                                        }
                                                    )}
                                                </>
                                            : ''
                                    }
                                </Stack>
                            </Stack>
                        </Stack>

                    )
                })
            }
            </Stack>
            <Container sx={{position: 'absolute', bottom: 0, left: 0}}>
                <Typography>{playlists[currentIndex].description}</Typography>
                <Grid container spacing={0} >
                    <Grid size={2} ><Button onClick={() => showPreviousArtist()}><Typography textAlign={'left'} sx={{color: '#000'}} ><NavigateBeforeIcon /></Typography></Button></Grid>
                    <Grid size={8} ><Link to={`playlist/${playlists[currentIndex]._id}`}><Typography variant='h5' color={'primary'}>{playlists[currentIndex].name}</Typography></Link></Grid>
                    <Grid size={2} ><Button onClick={() => showNextArtist()}><Typography textAlign={'left'} sx={{color: '#000'}} ><NavigateNextIcon /></Typography></Button></Grid>
                </Grid>
            </Container>

        </Item>
    :
        <Skeleton variant="rectangular" width={'50%'} height={'100%'}/>
    
}
