import { Avatar, Button, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'
import { selectArtistResults, selectTrackResults } from '../../../actions/discoverSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ShareIcon from '@mui/icons-material/Share'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import PauseIcon from '@mui/icons-material/Pause'
import placeholder from '../../../assets/placeholder_artist.jpg'
import { useLikeByTypeMutation } from '../../../api/userApiSlice'
import Grid from '@mui/material/Grid2/Grid2'
import { setAddPlaylistTrack } from '../../../actions/artistSlice'
import { setShowSubscribe } from '../../../actions/mainSlice'
import { useGetUserQuery } from '../../../api/userApiSlice'

const LeftItem = styled(Grid)(({ theme }) => ({
    height: '50%',
    padding: "8px",
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    overflow: 'hidden'
}))

const SearchCardResults = () => {
  const dispatch = useDispatch()
  const [likeContent] = useLikeByTypeMutation()

  const findedArtists = useSelector(selectArtistResults)
  const findedTracks = useSelector(selectTrackResults)
  const playingState = null
  const currentTrackId = null

  const {
    data: user,
    refetch
  } = useGetUserQuery({})
  console.log(user)
  const likeTrackById = async (ID: string) => {
    try {
      const like = await likeContent({target_id: ID, type: 'track'}).unwrap()
      refetch()
      if(like) {
          console.log('TODO: Use like')
      }
    } catch (error) {
      dispatch(setShowSubscribe({showSubscribe: true}))
    }
  }

  const likeArtistById = async (ID: string) => {
    try {
      const like = await likeContent({target_id: ID, type: 'artist'}).unwrap()
      refetch()
      if(like) {
          console.log('TODO: Use like')
      }
    } catch (error) {
      dispatch(setShowSubscribe({showSubscribe: true}))
    }
  }

  const addTrackToPlaylistById = async (track: Track) => {
      dispatch(setAddPlaylistTrack({track: track}))
      // try {
      //     var getPlaylist: any = await useGetUserPlaylistsQuery
      //     if (getPlaylist.error) {
      //         openSubscribePopup()
      //     } else {
      //         console.log(getPlaylist)
      //     }
      // } catch (err) {
      //     console.log(err)
      // }
  }

  const playTrack = async (track: Track) => {
      // await dispatch(authAppleMusicAsync())
      // await dispatch(addTrackToQueueAsync({track}))
      // dispatch(playTrackAsync())
  }

  const pause = async () => {
      // dispatch(pauseTrackAsync())
  }

  return (
    <Grid container spacing={1} sx={{height: '90%'}}>
        <LeftItem size={12}>
          <Typography variant='subtitle1' sx={{color: '#08084F', height: '5%'}}>Artiste(s) ({findedArtists ? findedArtists.length : '--'})</Typography>
          <Stack sx={{ overflow: 'hidden', overflowY: 'scroll', height: '95%',  borderRadius: '16px'}}>
          {
            findedArtists && findedArtists.length >= 1
            ?
              findedArtists.map( (artist: Artist) => {
                return (
                //  
                  <Grid key={artist._id} container spacing={1} sx={{p: 1}}>
                    <Grid size={3} alignContent={'center'}>
                      <Link key={artist._id} to={`/artist/${artist._id}`} className='full-width' ><Avatar src={artist.avatar_url ? artist.avatar_url.replace("cw.png","cc.png") : placeholder} sx={{width: '6vh', height: '6vh'}}/></Link>
                    </Grid>
                    <Grid size={6} alignContent={'center'}>
                      <Grid container spacing={0}>
                        <Grid size={12}>
                        <Link key={artist._id} to={`/artist/${artist._id}`} className='full-width' ><Typography variant='h6' color={'primary.main'} sx={{fontSize: '1em', textAlign: 'left'}}>{artist.artist_name}</Typography></Link>
                        </Grid>
                        <Grid size={12}>
                          <Typography color={'primary.main'} sx={{fontSize: '0.8em', fontStyle: 'italic', textAlign: 'left'}}>{artist.genre_names.length > 0 ? artist.genre_names.join(', ') : '--'}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid size={3} alignContent={'center'}>
                      <Stack direction={'row'} justifyContent={'end'}>
                          <Button onClick={() => likeArtistById(artist._id)} color="primary" sx={{minWidth: '32px'}}>{ user && user.likes && user.likes.filter((like: any) => like.target_id === artist._id).length > 0 ?  <FavoriteIcon sx={{fontSize: 20}} /> : <FavoriteBorderIcon sx={{fontSize: 20}} /> }</Button>
                          {/* <Button color="primary" sx={{minWidth: '32px'}}><ShareIcon sx={{fontSize: 20}} color='primary.main'/></Button> */}
                      </Stack>
                    </Grid>
                  </Grid>
              )})
            :
            <Grid container spacing={2} alignContent={'center'} sx={{height: '10vh', p: 2}}>
              <Grid size={3}>
                <Skeleton variant='circular' sx={{width: '5vh', height: '5vh'}}/>
              </Grid>
              <Grid size={6} alignContent={'center'}>
                <Skeleton />
                <Skeleton />
              </Grid>
              <Grid size={3} alignItems={'end'} alignContent={'end'} alignSelf={'center'}>
                <Skeleton variant='rounded' sx={{width: '3vh', height: '3vh'}} />
              </Grid>
            </Grid>
          }
          </Stack>
        </LeftItem>
        <LeftItem size={12} >
        <Typography variant='subtitle1' sx={{color: '#08084F', height: '5%'}}>Track(s) ({findedTracks ? findedTracks.length : '--'})</Typography>
        <Stack sx={{ overflow: 'hidden', overflowY: 'scroll', height: '95%'}}>

          {
            findedTracks && findedTracks.length >= 1
            ?
              findedTracks.map( (track: Track) => {
                return (
                  <Grid key={track._id} container spacing={1} sx={{ pt: 1, pb: 1}} >
                      <Grid size={3} alignContent={'center'}>
                        <img className='image-fit' alt={track.album ? track.album.apple_name : 'none'} src={track.album ? track.album.apple_artwork_url.replace('{w}', '300').replace('{h}', '300') : 'none'}  />
                      </Grid>

                      <Grid size={9} sx={{position: 'relative'}}>
                        <Grid container>
                          <Grid size={12}>
                            <Link to={`/track/${track._id}`} ><Typography variant='h6' textAlign={'left'} color={'primary.main'} sx={{fontSize: '1em', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}} >{track.apple_title}</Typography></Link>
                            <Stack direction="row" sx={{overflow: 'scroll'}}>
                              {
                                track.main_artists.map( (artist, i) =>  {
                                  return i === track.main_artists.length - 1
                                      ? 
                                        <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography variant='caption' color={'primary.main'} sx={{marginRight: '4px', whiteSpace: 'nowrap'}} > { artist.artist_name + (track.featured_artists && track.featured_artists.length > 0 ? ' ft. ' : '') } </Typography></Link>
                                      : 
                                        <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography variant='caption' color={'primary.main'} sx={{marginRight: '4px', whiteSpace: 'nowrap'}}> { artist.artist_name + ',' } </Typography></Link>
                                  })
                              }
                              {
                                  track.featured_artists && track.featured_artists.length > 0
                                  ?
                                      track.featured_artists.map( (artist, i) =>  {
                                        return track.featured_artists && i === track.featured_artists.length - 1
                                            ? 
                                              <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography variant='caption' color={'primary.main'} sx={{marginRight: '4px', whiteSpace: 'nowrap'}}> { artist.artist_name } </Typography></Link>
                                            : 
                                              <Link key={i + artist._id} to={`/artist/${artist._id}`} ><Typography variant='caption' color={'primary.main'} sx={{marginRight: '4px', whiteSpace: 'nowrap'}}> { artist.artist_name + ', ' } </Typography></Link>
                                        })
                                  : 
                                    ''
                              }
                            </Stack>
                            {/* <Typography textAlign={'left'} color={'primary.main'}>{track.apple_id}</Typography> */}
                          </Grid>

                          <Grid size={12}>
                            <Stack direction={'row'} justifyContent={'flex-end'} sx={{position: 'absolute', bottom: 0, right: 0}}>
                              {
                                playingState && track.apple_id === currentTrackId
                                ? <Button color="primary" onClick={() => pause()} ><PauseIcon /></Button>
                                : <Button color="primary" onClick={() => playTrack(track)} ><PlayArrowIcon sx={{fontSize: 20}} /></Button>
                              }
                              <Button color="primary" onClick={() => addTrackToPlaylistById(track)}><PlaylistAddIcon sx={{fontSize: 20}} /></Button>
                              <Button color="primary" onClick={() => likeTrackById(track._id)}  sx={{minWidth: '32px'}}>{ user && user.likes && user.likes.filter((like: any) => like.target_id === track._id).length > 0 ?  <FavoriteIcon sx={{fontSize: 20}} /> : <FavoriteBorderIcon sx={{fontSize: 20}} /> }</Button>
                              <Button color="primary"><ShareIcon sx={{fontSize: 20}} /></Button>
                            </Stack>
                          </Grid>

                        </Grid>
                      </Grid>
                  </Grid>
                )
              })
            :
              <Grid container spacing={2} alignContent={'center'} sx={{height: '10vh', p: 2}}>
                <Grid size={3}>
                  <Skeleton variant='circular' sx={{width: '5vh', height: '5vh'}}/>
                </Grid>
                <Grid size={6} alignContent={'center'}>
                  <Skeleton />
                  <Skeleton />
                </Grid>
                <Grid size={3} alignItems={'end'} alignContent={'end'} alignSelf={'center'}>
                  <Skeleton variant='rounded' sx={{width: '3vh', height: '3vh'}} />
                </Grid>
              </Grid>
          }
          </Stack>
        </LeftItem>
      </Grid>

    )
}

export default SearchCardResults