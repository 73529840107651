import { useState } from 'react'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { Avatar, Button, Skeleton, Stack, Typography } from '@mui/material'
import { useGetLabelsQuery } from '../../../api/discoverApiSlice'
import { useSelector } from 'react-redux'
import { selectCurrentStyle } from '../../../actions/discoverSlice'
import ApartmentIcon from '@mui/icons-material/Apartment'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
    padding: '8px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 0,
}))

const LabelsFocus = () => {
    const selectedStyle = useSelector(selectCurrentStyle)
    var [currentIndex, setCurrentIndex] = useState(0) 

    const {
        data: labelStats,
        isFetching
    } = useGetLabelsQuery(selectedStyle)

    const showNextLabel = () => {
        if (currentIndex + 1 === labelStats.length || currentIndex > labelStats.length) {
            setCurrentIndex(0)
        } else {
            setCurrentIndex(currentIndex + 1)
        }
    }

    const showPreviousLabel = () => {
        if (currentIndex - 1 === -1 || currentIndex < 0) {
            setCurrentIndex(labelStats.length - 1)
        } else {
            setCurrentIndex(currentIndex - 1)
        }
    }

    // const showIndex = (index) => {
    //     setCurrentIndex(index)
    // }

    return labelStats && !isFetching && labelStats.length > 0
        ? 
            <Item sx={{height: '15%', borderRadius: '16px', overflow: 'hidden'}}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'} alignSelf={'center'} sx={{height: '100%'}}>
                    <Button onClick={() => showPreviousLabel()}><NavigateBeforeIcon /></Button>
                    <Stack alignItems={'center'} direction={'row'} sx={{width: '100%'}}>
                        <Avatar 
                            variant='square'
                            src={labelStats[currentIndex].label.medias.filter(media => media.ref_type === "logo").length > 0 ? labelStats[currentIndex].label.medias.filter(media => media.ref_type === "logo")[0].content : ''}
                            sx={{
                                p: 2,
                                backgroundColor: labelStats[currentIndex].label.medias.filter(media => media.ref_type === "logo").length > 0 ? '#08082F20' : '#08082F',
                                borderRadius: '16px',
                                m: 2, 
                                width: '2vh', 
                                height: '2vh', 
                                '& img': { objectFit: 'contain'}
                            }}
                            >
                                <ApartmentIcon />
                        </Avatar>
                        <Typography sx={{textAlign: 'left'}}>
                            <b>{labelStats[currentIndex].label.name.replaceAll('/', ' / ')}</b> <br></br>- <b>{labelStats[currentIndex].stat}</b> Tracks dans le top 100 -
                        </Typography> 
                    </Stack>
                   <Button onClick={() => showNextLabel()}><NavigateNextIcon /></Button>
                </Stack>
            </Item>
        : 
            <Skeleton variant="rectangular" width={'100%'} height={'25%'} sx={{bgcolor: '#000000E8'}}/>

}

export default LabelsFocus