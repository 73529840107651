import { Stack, Skeleton } from '@mui/material'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetAlbumTracksQuery } from '../../../api/albumApiSlice'
import TrackRow from '../../track/TrackRow'


const AlbumTracks = () => {
    let { id } = useParams()

    const {
        data: tracks,
        isFetching
    } = useGetAlbumTracksQuery({id})

    const trackSorted = useMemo(() => {
        var sortedTracks = tracks ? tracks.slice() : []
        return sortedTracks.sort((a, b) => a.track.apple_id > b.track.apple_id ? 1 : -1)
    }, [tracks])

    return (
        <Stack direction={'column'} sx={{overflow: 'scroll', height: '90vh', width: '100%'}}>
            {
                trackSorted && trackSorted !== null && !isFetching
                ?   
                    trackSorted.map( (track, i) => <TrackRow key={i} track={track.track} index={i} maxProducers={10} fromAlbum={true} />)             
                : 
                    <Skeleton variant="rectangular" width={'90vw'} height={'90vh'} />
            }
        </Stack>
    )
}

export default AlbumTracks