// import { Theme } from "@emotion/react"
import { Button } from "@mui/material"
import { pause, play, playAlbum } from "../../services/MusicPlayerApi"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import withMK from "../../hoc/withMK";

interface PlayPauseAlbumButtonProps extends MKProps {
    album: Album
    // style: SxProps<Theme>
}

const PlayPauseAlbumButton = (props: PlayPauseAlbumButtonProps) => {
    const currentTrackAlbumName = props.mk && props.mk.instance.queue.currentItem ? props.mk.instance.queue.currentItem.albumName : null
    const playing = props.mk?.instance?.isPlaying

    return playing && currentTrackAlbumName === props.album.apple_name
    ? <Button onClick={() => pause()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><PauseIcon htmlColor='white' /></Button>
    : currentTrackAlbumName === props.album.apple_name 
        ? <Button onClick={() => play()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><PlayArrowIcon htmlColor='white' /></Button>
        : <Button onClick={() => playAlbum({id: props.album.apple_id+'', apple_store: props.album.apple_store}, 0)} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><PlayArrowIcon htmlColor='white' /></Button>
}


const bindings = {
    [MusicKit.Events.mediaItemDidChange]: 'mediaItem',
    [MusicKit.Events.queueItemsDidChange]: 'queueItems',
    [MusicKit.Events.queuePositionDidChange]: 'queuePosition',
    [MusicKit.Events.playbackStateDidChange]: 'playbackState',
}
  

export default withMK( PlayPauseAlbumButton, bindings )