import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid2/Grid2'
import { Container, IconButton, Skeleton, Stack, Chip, Typography } from '@mui/material'
import placeholder from '../../assets/placeholder_artist.jpg';
import previous from '../../assets/ic_prev.png';
import styles from './Artist.module.css'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import {  useGetPlaylistQuery } from '../../api/playlistApiSlice';
import TracksGroupedByDate from './components/TracksGroupedByDate';
const parse = require('html-react-parser');


const PlaylistBoard = () => {

    let { id } = useParams()

    const {
        data
    } = useGetPlaylistQuery({id})

    useEffect(() => {
    })


    return data && data !== null
    ? (
            <Grid container spacing={2} sx={{height: '100%'}}>
                <Grid size={4} sx={{height: '100%',}}>
                    <Stack 
                        spacing={0} 
                        sx={{
                            height: '100%',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${data.artist && data.artist.apple_avatar ? data.artist.apple_avatar.replace("cw.png","cc.png") : placeholder})`
                            }}
                    >
                        <Container sx={{backdropFilter: 'blur(20px)  brightness(70%)', height: '100%', padding: '4.8vh!important', paddingTop: '0!important', position: 'relative'}}>
                            <Stack direction="row" alignItems="center">
                                <Link to={'/'}><IconButton  sx={{width: '2.2vh', height: '3vh', marginRight: '16px'}}><img alt="previous" height='100%' width='100%' src={previous}/></IconButton></Link>
                                <h1>{data.name}</h1>  
                            </Stack>
                            <img className={styles.avatar + ' image-cover'} alt={data.name} height='20%' width='40%' src={data.artist && data.artist.avatar_url ? data.artist.avatar_url.replace("cw.png","cc.png") : placeholder} />
                            <p className={styles.head}>{
                                data.description && data.description.length > 0 
                                ? parse(data.description)
                                : data.artist && data.artist.apple_notes.length > 0 
                                    ? data.artist.apple_notes
                                    : '--'
                            }</p>
                            <div className={styles.content}>{
                                data.tracks && data.tracks.length > 0 
                                ? <Typography>{'Temps total : ' + convertMillisToTime(data.tracks.map(track => track.apple_duration).reduce((a, b) => a + b))}</Typography>
                                : 'Temps total : --'
                            }</div>
                        </Container>
                    </Stack>
                </Grid>
                <Grid size={8} sx={{height: '100%'}}>
                    <Stack spacing={1} sx={{height: '100%'}}>
                        <Stack direction={'row'} sx={{height: '3vh', overflowY: 'hidden', overflowX: 'hidden', margin: '16px 0'}}>
                            <Chip 
                                key={'Tracks'}
                                label={'Tracks'} 
                                sx={{ 
                                    color:'#000',
                                    backgroundColor: '#FFFFFF',
                                    height: '2.6vh',
                                    borderRadius: '1.3vh',
                                    fontFamily: 'Montserrat',
                                    fontSize: '.5vw',
                                    marginRight: '18px',
                                    marginBottom: '18px',
                                    '& span': {padding: '0 18px'}
                                    }}
                            />
                            <Chip 
                                key={'Artistes'}
                                label={'Artistes'} 
                                variant="outlined"
                                sx={{ 
                                    color:'#FFF',
                                    height: '2.6vh',
                                    borderRadius: '1.3vh',
                                    fontFamily: 'Montserrat',
                                    fontSize: '.5vw',
                                    marginRight: '18px',
                                    marginBottom: '18px',
                                    '& span': {padding: '0 18px'}
                                    }}
                            />
                            <Chip 
                                key={'Stats'}
                                label={'Stats'} 
                                variant="outlined"
                                sx={{ 
                                    color:'#FFF',
                                    height: '2.6vh',
                                    borderRadius: '1.3vh',
                                    fontFamily: 'Montserrat',
                                    fontSize: '.5vw',
                                    marginRight: '18px',
                                    marginBottom: '18px',
                                    '& span': {padding: '0 18px'}
                                    }}
                            />
                        </Stack>
                        
                        <Stack sx={{height: '78vh', overflowY: 'scroll', overflowX: 'hidden', margin: '16px 0'}}>
                            <TracksGroupedByDate tracks={data.tracks} />
                        </Stack>
                    </Stack>
              </Grid>
            </Grid>
    )
    : <Skeleton/>
}




export default PlaylistBoard

const convertMillisToTime = (timeInMiliseconds) => {
    let h,m,s
    h = Math.floor(timeInMiliseconds/1000/60/60)
    m = Math.floor((timeInMiliseconds/1000/60/60 - h)*60)
    s = Math.floor(((timeInMiliseconds/1000/60/60 - h)*60 - m)*60)

    s < 10 ? s = `0${s}`: s = `${s}`
    m < 10 ? m = `0${m}`: m = `${m}`
    h < 10 ? h = `0${h}`: h = `${h}`

    return h === '00'
    ?
        `${m} m ${s} s`
    :
        `${h} h ${m} m ${s} s`

} 