import { ReactNode, useEffect, useState } from "react"
import translate from '../../utils/translations/Translations'
import { Skeleton } from "@mui/material"

interface MusicKitProviderProps {
    children: ReactNode
}
  
  
const MusicKitProvider = (props: MusicKitProviderProps) => {
    const [ready, setReady] = useState(false)
  
    useEffect(() => {
        const initMK = async () =>  { 

            const mk = MusicKit.configure({
                developerToken: 'eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkM3NzZTTTdKUFEifQ.eyJpYXQiOjE3MzMwMDEyMTcsImV4cCI6MTc0ODU1MzIxNywiaXNzIjoiRDYzUURROUg5VCJ9.051iXHibm1Da_XRc2uQeKGIm_HVO2lmNBjPHZP0z9Vc_RHqvEadga19Z8OcKHDaKCROF8oU6_85wOWHjf331fQ',
                app: {
                name: 'Meka',
                icon: '',
                build: '1.0beta1',
                version: '1.0beta1',
                },
                bitrate: MusicKit.PlaybackBitrate.HIGH,
            })

            const setLanguage = (countryCode: string = 'fr') => {
                translate.setLanguage(countryCode);
                //forceUpdate()
            }
    
            setLanguage(mk.storekit?.storefrontCountryCode)
    
            // const handler = (e: any) => {
            //     setLanguage(e.storefrontCountryCode);
            // }
    
            // mk.addEventListener(
            //     'storefrontCountryCodeDidChange',
            //     handler as () => void,
            // )  
    
            setReady(true)
        }

        initMK()
        .catch(console.error)

    }, [])
  

    if (!ready) {
        return <Skeleton sx={{height: '50vh'}}/>
    }
    
    return props.children
  
}

export default MusicKitProvider

