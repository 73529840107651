import {  useState } from 'react'
import placeholder from '../../../../assets/placeholder_artist.jpg';
import { Avatar, Box, Button, Chip, Skeleton, Stack, Typography } from '@mui/material'
import { useGetInterpretorsQuery } from '../../../../api/discoverApiSlice'
import { useSelector } from 'react-redux';
import { selectCurrentStyle } from '../../../../actions/discoverSlice';
import { Link } from 'react-router-dom';
import styles from './InterpretorsFocus.module.css'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PlayTopTracksButton from '../../../utils/PlayTopTracksButton';
import AlbumIcon from '@mui/icons-material/Album';
import useHorizontalScroll from '../../../../utils/HorizontalScroll';


const InterpretorsFocus = () => {
    const scrollRef = useHorizontalScroll()
    const selectedStyle = useSelector(selectCurrentStyle)
    var [currentIndex, setCurrentIndex] = useState(0) 

    const {
        data: interpretors,
        isFetching
    } = useGetInterpretorsQuery(selectedStyle)
    
    const showNextArtist = () => {
        if (currentIndex + 1 === interpretors.length || currentIndex > interpretors.length) {
        document.getElementById('interpretorsList0')?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(0)
        } else {
        document.getElementById('interpretorsList'+(currentIndex + 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        setCurrentIndex(currentIndex + 1)
        }
    }

    const showPreviousArtist = () => {
        if (currentIndex - 1 === -1 || currentIndex < 0) {
            document.getElementById('interpretorsList'+(interpretors.length - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            setCurrentIndex(interpretors.length - 1)
        } else {
            document.getElementById('interpretorsList'+(currentIndex - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            setCurrentIndex(currentIndex - 1)
        }
    }

    const showIndex = (index: number) => {
        setCurrentIndex(index)
    }

    const scrollSelected = () => {
        document.getElementById('interpretorsList'+currentIndex)?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
    }

    return interpretors && interpretors.length > 0 && !isFetching
    ?   
        <Box className={styles.interpretorItem} >
            <Stack className={styles.interpretorInfos} onMouseLeave={scrollSelected}>

                <Stack direction={'row'} alignItems={'center'} alignContent={'center'}>

                    <Typography textAlign={'left'} sx={{color: '#fff', p: 2, fontWeight: '700', width: '80%'}} >{ interpretors[currentIndex].artist_name }</Typography>

                    <Stack direction={'row'} textAlign={'left'}  sx={{color: '#fff', pr: 2}} >
                    { 
                        interpretors[currentIndex].genre_names.length > 0 
                        ? 
                            interpretors[currentIndex].genre_names.map( (name: string, key: number) => 
                                <Chip color={'secondary'} key={key} label={name} variant="outlined"/>
                            ) 
                        : 
                            <Chip color={'secondary'} key={'key'} label={'--'} variant="outlined"/> 
                    }
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} alignSelf={'center'} sx={{mr: 2}}>
                        <AlbumIcon />
                        <Typography textAlign={'left'} sx={{color: '#fff', p: 1, fontWeight: '700'}} >{ interpretors[currentIndex].refNumber }</Typography>
                    </Stack>

                </Stack>

                <Stack direction={'row'} alignItems={'center'}>

                    <Stack ref={scrollRef} alignItems={'start'} id={'interpretorsList'} className={styles.interpretors} direction={'row'} sx={{overflowX: 'auto', p: 1, height: '98%', '& *::-webkit-scrollbar': {width: '0px', height: '0px'}}}>
                        {
                            interpretors.map( (artist: Artist, i: number) => 
                                <Button className={styles.selectartistbutton} id={'interpretorsList'+i} key={i} onClick={() => showIndex(i)} sx={{borderRadius: currentIndex === i ? '2.5vh' : '2vh'}}>
                                    <Avatar 
                                        className={styles.selectartist} 
                                        src={artist.avatar_url ? artist.avatar_url.replace("cw.png","cc.png").replace("{w}","100").replace("{h}","80") : artist.apple_avatar ? artist.apple_avatar.replace("cw.png","cc.png").replace("{w}","800").replace("{h}","550") : placeholder} 
                                        sx={{
                                            width: currentIndex === i ? '5vh!important' : (currentIndex + 1 === i || currentIndex - 1 === i) ? '4vh' : '3vh', 
                                            height: currentIndex === i ? '5vh!important' : (currentIndex + 1 === i || currentIndex - 1 === i) ? '4vh' : '3vh', 
                                            border: currentIndex === i ? '4px solid #5151a0' : '2px solid #5151a0'
                                        }}
                                    />
                                </Button>
                            )
                        }
                    </Stack>

                    <Stack direction={'row'} alignSelf={'end'} sx={{pb: 1, width: '40%'}} justifyContent={'center'} alignContent={'center'}>
                        <Button onClick={() => showPreviousArtist()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}}><NavigateBeforeIcon /></Button>
                        <PlayTopTracksButton artistId={interpretors[currentIndex]._id} style={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}} />
                        <Button onClick={() => showNextArtist()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}} ><NavigateNextIcon /></Button>
                    </Stack>

                </Stack>
            </Stack>

            <Link to={`/artist/${interpretors[currentIndex]._id}`}>
                <img 
                    height='100%' 
                    width='100%' 
                    className="image-cover" 
                    src={interpretors && interpretors[currentIndex].avatar_url ? interpretors[currentIndex].avatar_url.replace("cw.png","cc.png").replace("{w}","800").replace("{h}","600") : placeholder} 
                    alt={interpretors ? interpretors[currentIndex].artist_name : ''} />
            </Link>
        </Box>
    :   
        <Skeleton variant="rectangular" width={'50%'} height={'100%'} sx={{bgcolor: '#000000A8'}} />
}

export default InterpretorsFocus