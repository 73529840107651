import React, { useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { selectSubscribPopup } from '../../actions/mainSlice';
import { setShowSubscribe } from '../../actions/mainSlice'
import { useDispatch, useSelector } from 'react-redux';


const SubscribePopup = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const show = useSelector(selectSubscribPopup)

    useEffect(() => {

    })

    const handleClose = () => {
        dispatch(setShowSubscribe({showSubscribe: false}))
    }

    const goToSubscibe = () => {
        dispatch(setShowSubscribe({showSubscribe: false}))
        navigate('/register')
    }

    return <Dialog open={show} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
            {"Merci de votre soutient !"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Vous pouvez creer un compte pour retrouver les artites et musiques que vous préférez !
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Plus tard</Button>
            <Button onClick={goToSubscibe} autoFocus>Je m'inscris !</Button>
        </DialogActions>
    </Dialog>
         
}

export default SubscribePopup