// .button {
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-color: transparent;
//     background-position: center;
//     border: none;
//     margin: 8px;
//     width: 1%;
//     height: 60px;
// }

export const image_cover = {
    border: '2px solid white',
    borderRadius: '8px',
    width: '100%',
    height: 80
}

// .previous {
//     background-image: url('../../assets/previous_track.png');
// }

// .next {
//     background-image: url('../../assets/next_track.png');
// }

// .playlist {
//     background-image: url('../../assets/Project_Icon.png');
// }

// .mode {
//     background-image: url('../../assets/Random_icon.png');
// }

// .sound {
//     background-image: url('../../assets/Speaker_Icon.png');
// }


export const artist_name = {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: '12px',
    marginRight: '8px'
}

export const song_title = {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: '12px',
    padding: '0'
}

export const song_producers = {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: '12px',
    position: 'absolute',
    right: 0,
}