import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import useHorizontalScroll from '../../../../utils/HorizontalScroll';
import styles from './AlbumsFocus.module.css'
// import AlbumIcon from '@mui/icons-material/Album';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PlayPauseAlbumButton from '../../../utils/PlayPauseAlbumButton';

interface AlbumFocusHoverProps {
    albums: [Album], 
    currentIndex: number, 
    action:(index:number) => void
}

const AlbumFocusHover = ({albums, currentIndex, action}:AlbumFocusHoverProps) => {
    const scrollRef = useHorizontalScroll()

    const showNextArtist = () => {
        if (currentIndex + 1 === albums.length || currentIndex > albums.length) {
        document.getElementById('scrollList0')?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        action(0)
        } else {
        document.getElementById('scrollList'+(currentIndex + 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        action(currentIndex + 1)
        }
    }

    const showPreviousArtist = () => {
        if (currentIndex - 1 === -1 || currentIndex < 0) {
            document.getElementById('scrollList'+(albums.length - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            action(albums.length - 1)
        } else {
            document.getElementById('scrollList'+(currentIndex - 1))?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
            action(currentIndex - 1)
        }
    }

    const showIndex = (e: any,index: number) => {
        e.target?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
        action(index)
    }

    const scrollSelected = () => {
        document.getElementById('scrollList'+currentIndex)?.scrollIntoView({block: "nearest", behavior: "smooth", inline: "center"})
    }

    return <Box onMouseLeave={scrollSelected} >
        <Stack className={styles.albumInfos}>
            <Stack direction={'row'}>

                <Stack sx={{}}>
                    <Typography textAlign={'left'} sx={{color: '#fff', p: 2, pb: 0, fontWeight: 500}}>
                        { albums[currentIndex].apple_name }
                    </Typography>

                    <Typography textAlign={'left'} sx={{color: '#fff', p: 0, pl: 2, fontWeight: 100}}>
                        {' par ' + albums[currentIndex].apple_artist_name + '' }
                    </Typography>
                </Stack>
                {/* <Stack alignContent={'end'} alignItems={'end'} alignSelf={'end'}>
                    <Stack direction={'row'} alignItems={'center'} sx={{p: 2, pb: 0, width: '80%'}}>
                        <AlbumIcon />
                        <Typography textAlign={'left'} sx={{color: '#fff', padding: '1rem', fontWeight: '700'}}>{ albums[currentIndex].refNumber }</Typography>
                    </Stack>
                </Stack> */}
            </Stack>

            <Stack direction={'row'} alignItems={'flex-end'} sx={{p:1}}>
                <Stack id='scrollList' ref={scrollRef} alignItems={'baseline'} direction={'row'} sx={{pl:2, pr:2, width: '100%', overflow: 'hidden', overflowY: 'scroll'}} >
                {
                    albums.map( (album, i) => 
                        <Button key={i} id={'scrollList' + i} className={styles.selectartistbutton} onClick={(e) => showIndex(e, i)}>
                            <Avatar 
                                variant='square'
                                className={styles.selectartist} 
                                src={album.apple_artwork_url.replace("{w}","1200").replace("{h}","800")} 
                                sx={{
                                    width: currentIndex === i ? '4vh!important' : currentIndex - 1 === i || currentIndex + 1 === i ? '3vh' : '2vh', 
                                    height: currentIndex === i ? '4vh!important' : currentIndex - 1 === i || currentIndex + 1 === i ? '3vh' : '2vh', 
                                    border: currentIndex === i ? '4px solid #5151a0' : '2px solid #5151a0', 
                                    borderRadius: '8px'
                                }}
                            />
                        </Button>
                    )
                }
                </Stack>

                <Stack direction={'row'}>
                    <Button onClick={() => showPreviousArtist()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}} ><NavigateBeforeIcon /></Button>
                    <PlayPauseAlbumButton album={albums[currentIndex]} />
                    <Button onClick={() => showNextArtist()} sx={{color: '#fff', p: 2, minWidth: '16px', borderRadius: '8px'}} ><NavigateNextIcon /></Button>
                </Stack>
            </Stack>
        </Stack>
    </Box>
}

export default AlbumFocusHover