import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { Main } from './features/main/Main';
import { Route, Routes } from 'react-router-dom';

import Login from './features/auth/Login';
import RequireAuth from './features/auth/RequireAuth';
import DiscoverBoard from './features/discover/DiscoverBoard';
import ArtistBoard from './features/artist/ArtistBoard.js';
import Register from './features/auth/Register';
import UserInfo from './features/user/components/UserInfo';
import NotFound from './features/notFound/404';
import AlbumBoard from './features/album/AlbumBoard';
import TrackBoard from './features/track/TrackBoard';
import PlaylistBoard from './features/playlist/PlaylistBoard';
import ArtistGraph from './features/artist/components/ArtistGraph';
import ArtistProjects from './features/artist/components/ArtistProjects';
import TracksGroupedByDate from './features/artist/components/TracksGroupedByDate';
import AlbumTracks from './features/album/components/AlbumTracks';
import AlbumArtists from './features/album/components/AlbumArtists';
import AlbumStats from './features/album/components/AlbumStats';
import TrackArtists from './features/track/components/TrackArtists';
import TrackStats from './features/track/components/TrackStats';
import PersistLogin from './features/auth/PersistLogin';
import MusicKitProvider from './core/providers/MusicProvider';
import AuthorizeProvider from './core/providers/AuthorizeProvider';
import QueueModalProvider from './core/providers/QueueProvider';

import './App.css';
import Profile from './features/user/Profile';

const firebaseConfig = {
  apiKey: "AIzaSyAiPdwgs5q3YGCuJVG5rS7yz_T2WIWHWBM",
  authDomain: "meka-fddeb.firebaseapp.com",
  projectId: "meka-fddeb",
  storageBucket: "meka-fddeb.firebasestorage.app",
  messagingSenderId: "34892021598",
  appId: "1:34892021598:web:943560b58aa67b091f99cf",
  measurementId: "G-81J20KE478"
};
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const theme = createTheme({
  typography: {
    "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    musique: {main: '#36B0B6'},  
    alternative: {main: '#317AD6'},  
    blues: {main: '#F1D637'},  
    bande_originale: {main: '#D63E31'},
    musique_classique: {main: '#E2D8DF'},  
    dance: {main: '#F2CE1E'},  
    electronique: {main: '#9731D6'},  
    variete_française: {main: '#39F9FA'},  
    hip_hop_Rap: {main: '#4E42ED'},  
    k_pop: {main: '#E5591E'},  
    jazz: {main: '#317ED6'},  
    metal: {main: '#31D67D'},  
    musique_pour_enfants: {main: '#4E42ED'},  
    musiques_du_monde: {main: '#F739CE'},        
    pop: {main: '#9331D6'},  
    rnb_soul: {main: '#4E42ED'},  
    reggae: {main: '#4E42ED'},
    white: { main: '#FFFFFF', contrastText: "#000" },
    black: { main: '#000000'},
    red: { main: '#c91818'},
    gray: {
      backgroundField: '#F5F5F5'
    },
    primary: {
      light: '#393958',
      main: '#08082F',
      dark: '#050520'
    },
    secondary: {
      light: '#56bfc5',
      main: '#2cafb7',
      dark: '#1e7a80'
    }
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <MusicKitProvider>
        <AuthorizeProvider>
          <QueueModalProvider>
            <Routes>
                    
              <Route element={<PersistLogin />} >        
                <Route element={<Main />} >        

                  <Route index element={<DiscoverBoard />} />
                  <Route path='login' element={<Login />} />
                  <Route path='register' element={<Register />} />

                  <Route path='artist/:id' element={<ArtistBoard />}>
                    <Route index  element={<ArtistProjects />} />
                    <Route path='projects' element={<ArtistProjects />}/>
                    <Route path='releases' element={<TracksGroupedByDate />}/>
                    <Route path='team' element={<ArtistGraph />}/>
                  </Route>

                  <Route path='track/:id' element={<TrackBoard />}>
                    <Route index  element={<TrackArtists />} />
                    <Route path='team' element={<TrackArtists />}/>
                    <Route path='stats' element={<TrackStats />}/>
                  </Route>

                  <Route path='album/:id' element={<AlbumBoard />}>
                    <Route index  element={<AlbumTracks />} />
                    <Route path='tracks' element={<AlbumTracks />}/>
                    <Route path='team' element={<AlbumArtists />}/>
                    <Route path='stats' element={<AlbumStats />}/>
                  </Route>

                  <Route path='playlist/:id' element={<PlaylistBoard />}>
                  </Route>

                  <Route element={<RequireAuth />}>
                    <Route path='profile' element={<Profile />}>
                      <Route index element={<UserInfo />} />
                      <Route path=':id' element={<UserInfo />} />
                      <Route path='tracks/likes' element={<UserInfo />} />
                      <Route path='artists/likes' element={<UserInfo />} />
                      <Route path='users/followers' element={<UserInfo />} />
                      <Route path='users/following' element={<UserInfo />} />
                      <Route path='users/settings' element={<UserInfo />} />
                    </Route>
                  </Route>

                </Route>
              </Route>
                
              <Route path='*' element={<Main/>} >
                <Route path='*' element={<NotFound />} />
              </Route>  
            
            </Routes>
          </QueueModalProvider> 
        </AuthorizeProvider>
      </MusicKitProvider>
    </ThemeProvider>
  )
}
  
export default App
