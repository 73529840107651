import { IconButton, ImageList, ImageListItem, ImageListItemBar, Skeleton } from '@mui/material'
import { useGetUserPlaylistsQuery } from '../../../api/userApiSlice'
import useHorizontalScroll from '../../../utils/HorizontalScroll'
import { useNavigate } from 'react-router-dom'

const UserPlaylistsBoard = () => {

    const { data: playlists, isLoading } = useGetUserPlaylistsQuery() 
    const scrollRef = useHorizontalScroll()
    const navigate = useNavigate()

    const goToPlaylist = (id) => {
        navigate(`/playlist/${id}`)
    }

    return (
        <ImageList
            ref={scrollRef}
            sx={{
                width: '100%',
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                gridAutoColumns: "minmax(160px, 1fr)"
            }}
            >
            { !isLoading && playlists && playlists.length > 0 
                ? playlists.map( playlist => {
                    return (
                        <ImageListItem key={playlist._id} sx={{mr: 2}} onClick={() => goToPlaylist(playlist._id)}>
                            { 
                                playlist.tracks.length > 1
                                ? 
                                <ImageList cols={2} sx={{width: '100%', height: '100%'}} rowHeight={'50%'} >
                                    { 
                                        playlist.tracks.slice(0,4).map( track => {
                                            return <ImageListItem><img key={track._id} src={ track.album.apple_artwork_url ? track.album.apple_artwork_url.replace("{w}","800").replace("{h}","600")  : ''} alt='cover' /></ImageListItem>
                                        })
                                    }
                                </ImageList>
                                : 
                                    playlist.tracks.slice(0,4).map( track => {
                                        return <img key={track._id} src={ track.album.apple_artwork_url ? track.album.apple_artwork_url.replace("{w}","800").replace("{h}","600")  : ''} alt='cover' />
                                    })
                            }
                            <ImageListItemBar title={playlist.name} subtitle={playlist.visibility} actionIcon={<IconButton></IconButton>}/>
                        </ImageListItem>
                    )
                })
                :   <Skeleton />
            } 
        </ImageList>
    )
}

export default UserPlaylistsBoard