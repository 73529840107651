import React from 'react'
import Paper from '@mui/material/Paper'
import styles from '../Discover.module.css'
import { styled } from '@mui/material/styles'
import { Box, Button, Stack, Avatar } from '@mui/material'
import logo_card from '../../../assets/logo_card.jpg'

const LeftItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
    padding: '16px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    borderTop: '1px solid #08082F',
}))

const FactsCard = () => {
    return (
        <LeftItem sx={{height: '25%', textAlign: 'left'}}>
            <h3 variant="h3" className={styles.title} >Actualités</h3>
            <Button className={styles.show_all}>Voir tout</Button>
            <Box sx={{height: '94%', display: 'flex', flexFlow: 'column'}}>
                <Box sx={{ paddingLeft: '0', height: '50%'}}>
                    <Stack direction="row" spacing={1} alignItems='center' sx={{height: '100%'}}>
                        <Avatar src={logo_card} sx={{height: '6vh', width: '6vh'}}/>
                        <Stack spacing={1} sx={{height: '45%'}}>
                            <p className={styles.name} >Mēkā ?</p>
                            <p className={styles.content} >On vous en parle plus en détails dans cet article.</p>
                            <Button className={styles.view_more} >En savoir plus</Button>
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{height: '100%'}}>
                        <Avatar src={logo_card} sx={{height: '6vh', width: '6vh'}}/>
                        <Stack spacing={1} sx={{height: '45%'}} >
                            <p className={styles.name} >Pourquoi un focus sur les producteurs ?</p>
                            <p className={styles.content} >On vous en parle plus en détails dans cet article.</p>
                            <Button className={styles.view_more} >En savoir plus</Button>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </LeftItem>
    )
}

export default FactsCard