import React from 'react'
import Paper from '@mui/material/Paper'
import styles from '../Discover.module.css'
import { styled } from '@mui/material/styles'
import { Box, Chip, Skeleton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentStyle, setCurrentStyle } from '../../../actions/discoverSlice'
import { useGetStylesQuery } from '../../../api/discoverApiSlice'

const LeftItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff',
    padding: '16px',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '16px',
    borderTop: '1px solid #08082F',
}))

const StylesCard = () => {
    const dispatch = useDispatch()
    const selectedStyle = useSelector(selectCurrentStyle)

    const {
        data: fetchedStyles,
        isFetching
    } = useGetStylesQuery()

    const updateCurrentStyle = (ID) => {
        dispatch( setCurrentStyle(ID) )
    }

    return (
        <LeftItem sx={{height: '30%', textAlign: 'left'}}>
            <h3 variant="h3" className={styles.title} >Genres</h3>
            {/* <Button className={styles.show_all}>Voir tout</Button> */}
            <Box sx={{ height: '90%', overflow: 'hidden', overflowY: 'scroll', scrollbarWidth: 'none', marginTop: '16px'}}>
                {
                    fetchedStyles && fetchedStyles.length > 0 && !isFetching 
                    ? fetchedStyles
                        .map((style, key) =>
                            style.apple_id+"" === selectedStyle+""
                            ?    
                                <Chip 
                                    key={key}
                                    label={style.apple_id === '34' ? 'Général' : style.apple_name} 
                                    sx={{ 
                                        color:'#FFF',
                                        backgroundColor: colorForGenre(style.apple_id),
                                        height: '2.6vh',
                                        borderRadius: '1.3vh',
                                        fontFamily: 'Montserrat',
                                        fontSize: '1.1vh',
                                        marginRight: '8px',
                                        marginBottom: '16px',
                                        '& span': {padding: '0 18px'}
                                        }}
                                    onClick={() => updateCurrentStyle(style.apple_id)}
                                />
                            :
                                <Chip 
                                    key={key}
                                    label={style.apple_id === '34' ? 'Général' : style.apple_name} 
                                    variant="outlined"
                                    sx={{ 
                                        color:'#000',
                                        height: '2.6vh',
                                        borderRadius: '1.3vh',
                                        fontFamily: 'Montserrat',
                                        fontSize: '1.1vh',
                                        marginRight: '8px',
                                        marginBottom: '16px',
                                        '& span': {padding: '0 18px'}
                                        }}
                                    onClick={() => updateCurrentStyle(style.apple_id)}
                                />
                        ) 
                    : 
                        <Skeleton />
                }
            </Box>
        </LeftItem>
    )
}

export default StylesCard


const colorForGenre = (genre) => {
    switch (genre) {
        case '34': 
            return '#36B0B6'  
        case '20': 
            return '#317AD6'  
        case '2': 
            return '#F1D637'  
        case '16': 
            return '#D63E31'  
        case '5': 
            return '#E2D8DF'  
        case '17': 
            return '#F2CE1E'  
        case '7': 
            return '#9731D6'  
        case '55': 
            return '#39F9FA'  
        case '18': 
            return '#4E42ED'  
        case '51': 
            return '#E5591E'  
        case '11': 
            return '#317ED6'  
        case '1153': 
            return '#31D67D'  
        case '4': 
            return '#4E42ED'  
        case '19': 
            return '#F739CE'        
        case '14': 
            return '#9331D6'  
        case '15': 
            return '#4E42ED'  
        case '24': 
            return '#4E42ED'

        default:
            return '#4E42ED'
    }
}