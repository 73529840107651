import { ImageList, ImageListItem, ImageListItemBar, Skeleton } from '@mui/material'
import React from 'react'
import { useGetUserArtistsFollowedQuery } from '../../../api/userApiSlice'
import useHorizontalScroll from '../../../utils/HorizontalScroll'
import { useNavigate } from 'react-router-dom'

const UserArtistsBoard = () => {

    const { data:  likes, isLoading } = useGetUserArtistsFollowedQuery() 
    const scrollRef = useHorizontalScroll()
    const navigate = useNavigate()

    const goToArtist = (id) => {
        navigate(`/artist/${id}`)
    }

    return (
        <ImageList
            ref={scrollRef}
            sx={{
                width: '100%',
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                gridAutoColumns: "minmax(160px, 1fr)"
            }}
            >
            { !isLoading && likes && likes.length > 0 
                ? likes.map( like => like.target_id ).map( artist => {
                    return (
                        <ImageListItem key={artist._id} sx={{mr: 2}} onClick={() => goToArtist(artist._id)}>
                            <img src={artist.apple_avatar ? artist.apple_avatar.replace('cw.', 'cc.') : artist.avatar_url} alt='cover' />
                            <ImageListItemBar title={artist.artist_name} subtitle={artist.genre_names.join(',')} />
                        </ImageListItem>
                    )
                })
                :   <Skeleton />
            } 
        </ImageList>
    )
}

export default UserArtistsBoard