import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userToken: null,
    welcomPopup: localStorage.getItem('welcom_popup'),
    subscribe: false,
    recentArtists: localStorage.getItem('recent_artists') ? localStorage.getItem('recent_artists').split(',') : []
};

// export const initializeMusicKitAsync = createAsyncThunk(
//     'main/initializeMusicKit',
//     async () => {
//       return MusicProvider.sharedProvider().configure()
//       .then(function (instance) {
//         console.log(instance)
//         MusicProvider.sharedProvider().getMusicInstance().addEventListener('playbackStateDidChange', (state) => {
//           console.log(state)
//           console.log(state.nowPlayingItem.attributes.name)
//           updatePalyerState({mediaItem: state.nowPlayingItem})
//         })
        
//         return MusicProvider.sharedProvider().getMusicInstance()
//       })
//       .catch(err => console.log(err))
//     }
// )


export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
      userFirstAction : (state) => {
        state.welcomPopup = true
        localStorage.setItem('welcom_popup', true)
      },
      saveRecentArtist: (state, action) => {
        var recentArtists = state.recentArtists
        if(!recentArtists.includes(action.payload)) {
          if(recentArtists.length === 6) {
            recentArtists.shift()
          } else if(recentArtists.length > 6) {
            recentArtists = recentArtists.slice(0,6)
            recentArtists.shift()
          }
          recentArtists.push(action.payload)
          state.recentArtists = recentArtists
          localStorage.setItem('recent_artists', state.recentArtists)
        }
      },
      setShowSubscribe: (state, action) => {
        const { showSubscribe } = action.payload
        state.subscribe = showSubscribe
      }
    },
    extraReducers: builder => {
      // builder
        // .addCase(initializeMusicKitAsync.pending, (state, action) => {
        //   state.status = 'loading'
        // })
        // .addCase(initializeMusicKitAsync.fulfilled, (state, action) => {
        //   state.status = 'idle'
        //   action.payload.addEventListener('playbackProgressDidChange', (state) => {
        //     console.log(state)
        //   })
        // })
    }
  })

  export const { userFirstAction, saveRecentArtist, setShowSubscribe } = mainSlice.actions

  export const selectWelcomPopup = (state) => state.main.welcomPopup;
  export const selectSubscribPopup = (state) => state.main.subscribe;
  export const selectRecentArtists = (state) => state.main.recentArtists;

  export default mainSlice.reducer
