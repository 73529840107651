import { useEffect } from 'react'
import { useSendLogoutMutation } from '../../../api/authApiSlice'
import { useGetUserQuery } from '../../../api/userApiSlice'
import { Button, Skeleton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2/Grid2'
import moment from 'moment/moment'
import UserArtistsBoard from './UserArtistsBoard'
import UserPlaylistsBoard from './UserPlaylistsBoard'
import withMK from '../../../hoc/withMK'

interface UserInfoProps extends MKProps {
  autorized: boolean
}

const UserInfo = (props: UserInfoProps) => {
  const amAuthToken = props.mk?.instance?.musicUserToken
  const [ logOut ] = useSendLogoutMutation()
  const {
    data: user,
    isLoading,
    isError,
    
  } = useGetUserQuery({})

  useEffect(() => {

  }, [])

  const logoutHandler = async () => {
    await logOut({})
  } 


  const creationDateStr = () => {
    const crDate = user ? moment(user.creationDate) : moment('08/08/1990')
    crDate.local(true)
    return crDate.format('LL')
  }

  return isLoading 
    ? <Skeleton/>
    : isError 
      ? <Typography>{'erreur'}</Typography>
      : user 
        ? 
        <Grid container spacing={2} sx={{height: '80vh', overflow: 'hídden'}}>

          <Grid size={12} sx={{
              // backgroundColor: "#00000060",
              borderRadius: '24px',
              overflow: 'hidden',
              m: 2
            }}>
              <Typography variant='h4'>Artistes suivis</Typography>
              <UserArtistsBoard />
          </Grid>
          <Grid size={12} sx={{
              // backgroundColor: "#00000060",
              // border: '1px solid white',
              borderRadius: '24px',
              overflow: 'hidden',
              m: 2
            }}>
              <Typography variant='h4'>Mes playlists</Typography>
              <UserPlaylistsBoard />
          </Grid>
          {/* <Grid size={12} sx={{
              // backgroundColor: "#00000060",
              height: '20vh',
              borderRadius: '24px',
              m: 2
            }}>
              <Typography variant='h4'>Mes événements</Typography>
              <Typography variant='caption'>À venir</Typography>

          </Grid> */}

          <Grid size={12}         
            sx={{
              backgroundColor: "#00000060",
              p: 2,
              m: 2,
              borderRadius: '24px'
            }}>
            <Typography>E-mail : {user.email}</Typography>
            <Typography>Membre depuis le {creationDateStr()}</Typography>
            <Typography>Vérifié : {user.virified ? "oui" : "non"}</Typography>
            <Typography>Votre role : {user.role}</Typography>
            <Typography>AM auth : {amAuthToken ? amAuthToken.slice(0, amAuthToken.length / 4) : <Button  onClick={() => props.mk?.instance?.authorize()} sx={{color: 'white.main'}}>Log in</Button>}</Typography>
            <Button onClick={logoutHandler} sx={{color: 'red.main'}} >Déconnexion</Button>
          </Grid>
        </Grid>
        :  <Typography>"No user"</Typography>
}

const bindings = {
  [MusicKit.Events.authorizationStatusDidChange]: 'mediaItem',
}

export default withMK(UserInfo, bindings)