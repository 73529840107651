import Chart from 'react-apexcharts';
import {  Skeleton } from '@mui/material'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetTrackQuery, useGetTrackStatsQuery } from '../../../api/trackApiSlice'
import moment from 'moment';
import Grid from '@mui/material/Grid2/Grid2';
import { useGetUserQuery } from '../../../api/userApiSlice';


const TrackStats = () => {
    let { id } = useParams()

    const {
        data: user
    } = useGetUserQuery()

    const {
        data: track,
    } = useGetTrackQuery({id, user_id: user ? user._id : null})


    const {
        data: trackStats,
        isFetchingStats
    } = useGetTrackStatsQuery(id)


    const statsByStyle = useMemo(() => {
        if (!trackStats || !trackStats[0].stats)
            return []
        var statsOrdered = []
        var styles = [...new Set(trackStats[0].stats.map( stat => stat.style ))]
        styles.map( style =>
            statsOrdered[style] =  trackStats[0].stats.filter( stat => stat.style === style) 
        )
        
        return statsOrdered
    }, [trackStats])


    return statsByStyle && statsByStyle.length > 0 && !isFetchingStats
    ?   <Grid size={12} sx={{overflow: 'hidden', height: '90vh', width: '100%'}}>
        {
            statsByStyle.map((stats, i) => 
            <Chart options={{
                grid: {
                    show: false
                },
                theme: {
                    mode: 'dark', 
                    monochrome: {
                        enabled: true,
                        color: '#08088A',
                        shadeTo: 'light',
                        shadeIntensity: 0.65
                    }
                },
                chart: {
                    id: 'apexchart-example',
                    foreColor: '#FFFFFF',
                    background: '#ffffff00',
                    toolbar: {
                        show: false,
                    },
                    animations: {
                        enabled: false,
                    }
                },
                yaxis: {
                    min: 1,
                    max: Math.max(200, Math.max(...stats.map( stat => stat.position ))),
                    reversed: true,
                    position: 'right'
                },
                xaxis: {
                    position: 'top',
                    categories: stats.map( stat => moment(stat.date).format('DD/MM') )
                }
                
                }} 
                series={[{
                    name: track.apple_title,
                    data: stats.map( stat => stat.position )                            
                }]}
                key={i}
                type="line" 
                width={'100%'} 
                height={'100%'} />
            ) 
        }
        </Grid>
    : 
        <Skeleton variant="rectangular" width={'50vw'} height={'80%'} sx={{backgroundColor: '#00000060', p: 2, m: 2}}/>
}

export default TrackStats