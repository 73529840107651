import { Avatar, Box, Skeleton, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2/Grid2'
import { Link, useParams } from 'react-router-dom'
import { useGetArtistTracksQuery } from '../../../api/artistApiSlice'
import MusicOffIcon from '@mui/icons-material/MusicOff';


const ArtistProjects = () => {
    let { id } = useParams()

    const {
        data: tracks,
        isFetching
    } = useGetArtistTracksQuery({id})

    const tracksToShow = sortTracks(tracks)

    return tracksToShow && tracksToShow !== null && !isFetching
    ?
        <Box sx={{overflow: 'hidden', height: '100%'}}>
            <Grid size={12} sx={{overflowY: 'scroll', overflowX: 'hidden', height: '100%', width: '100%', position: 'relative' }}>
                <Grid container spacing={0} >
                { 
                    tracksToShow.length > 0
                    ? 
                        <Box>
                            <Typography variant='h3' sx={{fontSize: '2vmin', fontWeight: 800 }}>Albums / EPs</Typography>
                            <Grid container>
                            {
                                tracksToShow.filter(track => id && track.main_artists.length === 1 && track.main_artists.map(artist => artist._id).includes(id)).length === 0
                                ? <MusicOffIcon/>
                                :
                                    tracksToShow.filter(track => id && track.main_artists.length === 1 && track.main_artists.map(artist => artist._id).includes(id))
                                    .map( (track, i) => 
                                        <Grid size={6} key={Date.now() + i + track._id} sx={{position: 'relative', p:1}}>
                                            <Link to={`/album/${track.album._id}`}>
                                                <Avatar variant="square" alt={track.album.apple_name} src={track.album.apple_artwork_url ? track.album.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : ''} sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            </Link>
                                        </Grid>
                                    )
                            }
                            </Grid>
                            <Typography variant='h3' sx={{fontSize: '2vmin', fontWeight: 800 }}>Productions</Typography>
                            <Grid container>
                            {
                                tracksToShow.filter(track => id && track.producers?.map(artist => artist._id).includes(id)).length === 0
                                ? <MusicOffIcon/>
                                :
                                    tracksToShow.filter(track => id && track.producers?.map(artist => artist._id).includes(id))
                                    .map( (track, i) => 
                                        <Grid size={4} key={Date.now() + i + track._id} sx={{position: 'relative', p:1}}>
                                            <Link to={`/album/${track.album ? track.album._id : 'noid'}`}>
                                                <Avatar variant="square" alt={track.album ? track.album.apple_name : 'noname'} src={track.album ? (track.album.apple_artwork_url ? track.album.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : '') : ''} sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            </Link>
                                        </Grid>
                                    )
                            }
                            </Grid>
                            <Typography variant='h3' sx={{fontSize: '2vmin', fontWeight: 800 }}>Collaborations</Typography>
                            <Grid container>
                            {
                                tracksToShow.filter(track => ((id && track.featured_artists?.map(artist => artist._id).includes(id))) || (id && track.main_artists.length > 1 && track.main_artists.map(artist => artist._id).includes(id))).length === 0
                                ? <MusicOffIcon/>
                                :
                                    tracksToShow.filter(track => (id && track.featured_artists?.map(artist => artist._id).includes(id)) || (id &&track.main_artists.length > 1 && track.main_artists.map(artist => artist._id).includes(id)))
                                    .map( (track, i) => 
                                        <Grid size={2} key={Date.now() + i + track._id} sx={{position: 'relative', p:1}}>
                                            <Link to={`/album/${track.album._id}`}>
                                                <Avatar variant="square" alt={track.album.apple_name} src={track.album.apple_artwork_url ? track.album.apple_artwork_url.replace("cw.png","cc.png").replace("{w}","1200").replace("{h}","800") : ''} sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                                            </Link>
                                        </Grid>
                                    )
                            }
                            </Grid>
                        </Box>
                    :
                        <MusicOffIcon sx={{width: '4vh', height: '4vh', position: 'absolute', right: '50%', top: '50%', transform: 'translate(50%, -50%)'}} />
                }
                </Grid>  
            </Grid>  
        </Box>
    : <Skeleton variant="rectangular" width={'80vw'} height={'80vh'} />
}

export default ArtistProjects

const sortTracks = (tracks: [Track]) => {
    if (!tracks)
        return null
    const projects = [...new Set<string>(tracks.map( (track: Track) => track.album ? track.album.apple_id+'' : ''))]

    return projects.map( (albumID, i) => {
        return tracks.filter((track: Track) => (track.album ? track.album.apple_id+'' : '') === albumID ).slice(0,1)[0]                   
    })
}