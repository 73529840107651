import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2/Grid2';
import { Box, Button, Modal, Typography } from '@mui/material'
import { SideMenu } from '../sidemenu/SideMenu';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectWelcomPopup, userFirstAction } from '../../actions/mainSlice';
import thinking from '../../assets/growing.png'
import ensemble from '../../assets/ensemble.png'
import musicprod from '../../assets/musicprod.png'
import piano from '../../assets/piano.png'
// import firebase from "firebase/app";
import Queue from '../player/queue/Queue';
import AddPlaylistDialog from '../dialog/AddPlaylistDialog';
import SubscribePopup from '../dialog/SubscribePopup';
import { selectCurrentUser } from '../../actions/authSlice';
// import getAnalytics from "firebase/analytics";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '88vw',
  maxWidth: 808,
  height: '90vh',
  overflowY: 'scroll',
  overflowX: 'hidden',
  bgcolor: 'primary.main',
  border: '2px solid #000',
  color: '#fff',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4,
};

const center = {
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  left: '50%'
};

const centerContainer = {
  position: 'relative'
};

export function Main() {
//   const analytics = getAnalytics();
    const dispatch = useDispatch();
    const welcomPopup = useSelector(selectWelcomPopup)
    const user = useSelector(selectCurrentUser)

    useEffect(() => {
    })

    const enterSite = () => {
        dispatch(userFirstAction())
        // firebase.logEvent(analytics, 'onboarding_done', {});
    }

    return <Box className="App" sx={{backgroundColor: '#FFFFFFEE', height: '100vh', overflow: 'hidden'}}>
      <Grid container spacing={1} sx={{backgroundColor: '#17043C', height: "100%", width: '100%', color: '#FFF', overflowY: 'hidden', borderRadius: '0px', p: 2, marginTop:'0vh', marginLeft: '0vh', position: 'relative'}}>
        { user ? <AddPlaylistDialog /> : '' }
        <SubscribePopup />
        <Modal
            open={(welcomPopup === null && welcomPopup !== true)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Grid container spacing={2} sx={style}>
                <Grid size={12} sx={{mb: 4}}> 
                    <Typography  textAlign='center' id="modal-modal-title" variant="h3" component="h2">
                    Bienvenu sur Meka !
                    </Typography>
                </Grid>
                <Grid container spacing={2} sx={{padding: '1.8rem'}}>
                    <Grid size={10}> 
                        <Typography id="modal-modal-description" sx={{ padding: '0 1rem', textAlign: 'left' }}>
                        Notre plateforme est le point de départ de plusieurs idées. C'est encore un peu flou
                        et c'est volontaire. On veut garder un peu de mystère et d'intérêt, mais surtout 
                        on veut la construire ce projet avec vous.
                        </Typography>
                    </Grid>
                    <Grid size={2} style={centerContainer}>
                        <img alt='en reflexion' style={center} src={thinking} width='100vh' height='100vh' />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{padding: '1.8rem'}}>
                    <Grid xs={3} style={centerContainer}>
                        <img alt='ensemble' style={center} src={ensemble} width='100vh' height='100vh' />
                    </Grid>
                    <Grid size={9}> 
                        <Typography id="modal-modal-description" sx={{padding: '0 1rem', textAlign: 'left'}}>
                        Notre premier objectif est de réunir une communauté. <br></br><br></br>
                        <b>Vous aimez la musique ? </b><br></br>
                        <b>Bienvenu</b>, vous êtes avec nous ! <br></br><br></br>
                        Vous pouvez nous joindre ici : <a href='mailto:pim@meka-app.com'>pim@meka-app.com</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{padding: '1.8rem'}}>
                    <Grid size={10} > 
                        <Typography id="modal-modal-description" sx={{ padding: '0 1rem', textAlign: 'left' }}>
                        À ce jour, vous allez pouvoir trouver, à travers nos expériences, le résultat d'une volonté
                        de mettre en lumière les créateurs de la musique. Avec cette envie, le premier profil qui nous est 
                        paru évident est celui des producteurs.
                        </Typography>
                    </Grid>
                    <Grid size={2} style={centerContainer}>
                        <img alt='production musique' style={center} src={musicprod} width='100vh' height='80vh' />
                    </Grid>
                </Grid>
                <Grid container spacing={3} sx={{padding: '1.8rem'}}>
                    <Grid size={2} style={centerContainer}>
                        <img alt='composition' style={center} src={piano} width='100vh' height='75vh' />
                    </Grid>
                    <Grid size={10}> 
                        <Typography id="modal-modal-description" sx={{padding: '0 2rem', textAlign: 'left' }}>
                        C'est leur métier qui inspire le nom de cet endroit, ces personnes qui fabriquent la musique,
                        tels des artisans au savoir millénaire. 
                        </Typography>
                    </Grid>
                    <Grid size={12}>
                        <Typography id="modal-modal-description" sx={{padding: '0 1rem', textAlign: 'right' }}>
                        メーカー。作る人。
                        </Typography>
                    </Grid>
                </Grid>
                <Grid size={5} xsOffset={7} sx={{mt: 3}} >
                    <Button color='white'><a href='mailto:pim@meka-app.com'>En savoir plus</a></Button>
                    <Button color='white' onClick={() => enterSite()}>Accéder au site</Button>
                </Grid>
            </Grid>
        </Modal>
        <Queue />
        <SideMenu />
        <Grid size={{ lg:10, md: 10, xs: 12}} sx={{height:  '100%'}}><Outlet /></Grid>
      </Grid>
    </Box>
}
