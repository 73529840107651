import { configureStore } from '@reduxjs/toolkit';
import discoverReducer from '../actions/discoverSlice';
import authReducer from '../actions/authSlice';
import artistReducer from '../actions/artistSlice';
import { apiSlice } from '../api/apiSlice';
import playerSlice from '../actions/playerSlice';
import mainSlice from '../actions/mainSlice';
import userSlice from '../actions/userSlice';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    discover: discoverReducer,
    auth: authReducer,
    artist: artistReducer,
    player: playerSlice,
    user: userSlice,
    main: mainSlice
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch